/* eslint-disable react-hooks/exhaustive-deps */

import { Fragment, useContext, useEffect, useState } from 'react'
import {
  EKingOfCollection,
  ICardType,
  IDataCards,
  IFilters,
  MagicKindOfStore,
  IDataFilter,
  IRowsPSE,
  IRowsStores,
} from './SearchFilesCollections.interface'
import styles from './SearchFilesCollectionsView.module.scss'
import { Autocomplete, CircularProgress, Stack, TextField } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faFileExcel,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons'
import { format, subDays } from 'date-fns'
import DateRangePicker from 'src/components/DatepickerComponent/DatepickerComponent'
import useLoading from 'src/hooks/useLoading'
import { getAllStores } from 'src/services/accounting/accountingService'
import useIsMounted from 'src/hooks/useIsMounted'
import GlobalLoading from 'src/components/Loadings/GlobalLoading'
import TablePSE from './components/TablePSE'
import TableStores from './components/TableStores'
import {
  downloadCollectionsPSE,
  downloadCollectionsStores,
  getCollectionsPSE,
  getCollectionsStoresWS,
} from 'src/services/accounting/collectionsService'
import { formatToCOP } from 'src/utils/utils'
import { WebSocketContext } from 'src/contexts/WebSocketContext'
import { useNavigate } from 'react-router-dom'
import React from 'react'

const SearchFilesCollectionsView = () => {
  //HOOKS
  const { message, setMessage } = useContext(WebSocketContext)
  const navigate = useNavigate()
  const isMounted = useIsMounted()
  const { isLoading, startLoading, stopLoading } = useLoading()
  const [page, setPage] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [rowsStores, setRowsStores] = useState<IRowsStores[] | null>(null)
  const [rowsPSE, setRowsPSE] = useState<IRowsPSE[] | null>(null)
  const [loadingSelect, setLoadingSelect] = useState<boolean>(false)
  const [storesDistributors, setStoresDistributors] = useState<
    IDataFilter[] | null
  >(null)
  const headersPSE = [
    {
      label: '',
      columns: 1,
    },
    {
      label: 'Pagos ACH',
      columns: 2,
    },
    {
      label: 'Pagos PSE Suite',
      columns: 2,
    },
    {
      label: 'Pagos PSE Core',
      columns: 2,
    },
    {
      label: 'Reversos',
      columns: 4,
    },
  ]
  const headersStores = [
    {
      label: '',
      columns: 1,
    },
    {
      label: 'Pagos Suite',
      columns: 2,
    },
    {
      label: 'Pagos Core',
      columns: 2,
    },
    {
      label: 'Diferencia',
      columns: 2,
    },
    {
      label: 'Reversos',
      columns: 4,
    },
  ]
  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)

    console.log(event.target.value, 'event.target.value')
  }

  const [dataModule, setDataModule] = useState<IDataCards | null>(null)
  const [cardsData, setCardsData] = useState<ICardType[] | null>(null)
  useEffect(() => {
    setCardsData([
      {
        color: '#000bff',
        titleValue: 'Total recaudos Ach',
        value: Number(dataModule?.sumAchCount) || 0,
        titleAmount: 'Valor',
        amount: Number(dataModule?.sumAchAmount) || 0,
      },
      {
        color: '#000bff',
        titleValue: 'Total recaudos suite',
        value: Number(dataModule?.sumSuiteCount) || 0,
        titleAmount: 'Valor',
        amount: Number(dataModule?.sumSuiteAmount) || 0,
      },
      {
        titleValue: 'Total recaudos core',
        value: Number(dataModule?.sumCoreCount) || 0,
        titleAmount: 'Valor',
        amount: Number(dataModule?.sumCoreAmount) || 0,
        color: '#ff8500',
      },
      {
        titleValue: 'Total reversos',
        value: Number(dataModule?.sumReverseSamePeriodCount) || 0,
        titleAmount: 'Valor',
        amount: Number(dataModule?.sumReverseSamePeriodAmount) || 0,
        color: '#8e6ec8',
      },
      {
        titleValue: 'Total recaudos netos (suite)',
        value: Number(dataModule?.netWorthCount) || 0,
        titleAmount: 'Valor',
        amount: Number(dataModule?.netWorthAmount) || 0,
        color: '#8e6ec8',
      },
    ])
  }, [dataModule])

  const [stores] = useState<string[]>(['Ventas', 'Recaudos'])
  const [kindOfCollection, setKindOfCollection] = useState<string>(
    EKingOfCollection.PSE,
  )
  useEffect(() => {
    setRowsPSE(null)
    setRowsStores(null)
    setDataModule(null)
  }, [kindOfCollection])
  const [filters, setFilters] = useState<IFilters>({
    startDate: String(new Date()),
    endDate: String(new Date()),
    kindOfStore: MagicKindOfStore.Nalsani,
    businessName: '',
    store: '',
  })

  // Efects to filters
  useEffect(() => {
    //LIMPIAR FILTROS
    //AGREGAR TIENDAS DE FRANQUICIA
    setFilters((prevFilters) => ({
      ...prevFilters,
      businessName: '',
      store: '',
    }))
  }, [filters.kindOfStore])
  const [storeDistributors, setStoreDistributors] = useState<
    IDataFilter[] | null
  >(null)
  const [storeFranchise, setStoreFranchise] = useState<IDataFilter[] | null>(
    null,
  )
  useEffect(() => {
    //SETEAR TIENDAS DE LA FRANQUICIA
    setStoreFranchise(null)
    setStoreDistributors(null)
    if (filters.kindOfStore === MagicKindOfStore.Franquicias) {
      const foundItem = businessName?.find(
        (item) => item.label === filters.businessName,
      )
      if (foundItem) {
        setStoreFranchise(foundItem.franchises || null)
      } else {
        setStoreFranchise(null)
      }
    }

    if (filters.kindOfStore === MagicKindOfStore.Nalsani) {
      setStoreFranchise(null)
    }

    if (filters.kindOfStore === MagicKindOfStore.Distribuidores) {
      const foundItem = storesDistributors?.find(
        (item) => item.label === filters.businessName,
      )
      console.log(foundItem, 'foundItem')

      if (foundItem) {
        setStoreDistributors(foundItem.stores || null)
      } else {
        setStoreDistributors(null)
      }
    }
  }, [filters.businessName])
  const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
    const formattedStartDate = startDate
      ? startDate.toISOString().split('T')[0].replaceAll('-', '/')
      : ''
    const formattedEndDate = endDate
      ? endDate.toISOString().split('T')[0].replaceAll('-', '/')
      : ''
    setFilters((prevFilters) => ({
      ...prevFilters,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }))
  }

  const [businessName, setBusinessName] = useState<IDataFilter[] | null>(null)
  const [storesNalsani, setStoresNalsani] = useState<IDataFilter[] | null>(null)
  //handler unico para todos los filtros
  const handleFilterChange = (
    value: {
      id: number
      label: string
      disabled: boolean
    },
    filter: string,
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: value?.label ?? '',
    }))
  }

  // GET STORES
  useEffect(() => {
    if (!isMounted) return
    const fetchData = async () => {
      try {
        setLoadingSelect(true)
        const stores = await getAllStores('pagostiendas')
        console.log(stores, 'stores')
        setBusinessName(stores.data.businessName)
        setStoresNalsani(stores.data.storesNalsani)
        setStoresDistributors(stores.data.distributors)
      } catch (error) {
        console.log(error, 'error')
      } finally {
        setLoadingSelect(false)
      }
    }
    fetchData()
  }, [isMounted])
  const handleSearch = () => {
    setMessage('Obteniendo datos...')
    if (kindOfCollection === EKingOfCollection.PSE) {
      handleGetCollectionsPSE()
    } else {
      handleGetCollectionsWS()
    }
  }
  const handleGetCollectionsPSE = async () => {
    try {
      setMessage('Obteniendo datos...')
      setRowsPSE(null)
      setDataModule(null)
      setTotalRows(0)
      startLoading()
      const response = await getCollectionsPSE({
        dateGte: format(new Date(filters.startDate), 'yyyy/MM/dd'),
        dateLte: format(new Date(filters.endDate), 'yyyy/MM/dd'),
        currentPage: page,
        pageSize: rowsPerPage,
        setMessage,
      })
      let rows = (response as { rows: IRowsPSE[] })?.rows
      let count = (response as { totalPages?: number })?.totalPages || 0
      let totalItems = (response as { totalItems?: number })?.totalItems || 0
      let dataModule = response as IDataCards
      setRowsPSE(rows)
      setDataModule(dataModule)
      setTotalRows(count)
      setTotalItems(totalItems)
      console.log(count, 'count')
    } catch (error) {
      console.log(error, 'error')
    } finally {
      stopLoading()
    }
  }
  const handleGetCollectionsWS = async () => {
    try {
      setMessage('Obteniendo datos...')
      setDataModule(null)
      setRowsStores(null)
      startLoading()
      setTotalRows(0)
      let response: any
      if (
        filters.kindOfStore === MagicKindOfStore.Franquicias ||
        filters.kindOfStore === MagicKindOfStore.Distribuidores
      ) {
        response = await getCollectionsStoresWS({
          dateGte: format(new Date(filters.startDate), 'yyyy/MM/dd'),
          dateLte: format(new Date(filters.endDate), 'yyyy/MM/dd'),
          currentPage: page,
          pageSize: rowsPerPage,
          kindOfStore: filters.kindOfStore,
          store: filters.store,
          businessName: filters.businessName,
          setMessage,
        })
      }
      if (filters.kindOfStore === MagicKindOfStore.Nalsani) {
        response = await getCollectionsStoresWS({
          dateGte: format(new Date(filters.startDate), 'yyyy/MM/dd'),
          dateLte: format(new Date(filters.endDate), 'yyyy/MM/dd'),
          currentPage: page,
          pageSize: rowsPerPage,
          kindOfStore: filters.kindOfStore,
          store: filters.businessName,
          setMessage,
        })
      }

      let rows = (response as { rows: IRowsStores[] })?.rows
      let count = (response as { totalPages?: number })?.totalPages || 0
      let totalItems = (response as { totalItems?: number })?.totalItems || 0
      let dataModule = response as IDataCards
      setDataModule(dataModule)
      setRowsStores(rows)
      setTotalRows(count)
      setTotalItems(totalItems)
      console.log(count, 'count')
    } catch (error) {
      console.log(error, 'error')
    } finally {
      stopLoading()
    }
  }
  const handleDownloadExcel = async () => {
    try {
      setMessage('Descargando archivo...')
      startLoading()
      if (kindOfCollection === EKingOfCollection.PSE) {
        await downloadCollectionsPSE({
          dateGte: format(new Date(filters.startDate), 'yyyy/MM/dd'),
          dateLte: format(new Date(filters.endDate), 'yyyy/MM/dd'),
          setMessage,
        })
      } else {
        if (filters.kindOfStore === MagicKindOfStore.Nalsani) {
          await downloadCollectionsStores({
            dateGte: format(new Date(filters.startDate), 'yyyy/MM/dd'),
            dateLte: format(new Date(filters.endDate), 'yyyy/MM/dd'),
            kindOfStore: filters.kindOfStore,
            store: filters.businessName,
            setMessage,
          })
        }
        if (
          filters.kindOfStore === MagicKindOfStore.Franquicias ||
          filters.kindOfStore === MagicKindOfStore.Distribuidores
        ) {
          await downloadCollectionsStores({
            dateGte: format(new Date(filters.startDate), 'yyyy/MM/dd'),
            dateLte: format(new Date(filters.endDate), 'yyyy/MM/dd'),
            kindOfStore: filters.kindOfStore,
            store: filters.store,
            businessName: filters.businessName,
            setMessage,
          })
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      stopLoading()
    }
  }
  return (
    <div>
      {isLoading && <GlobalLoading message={message} />}
      <Stack gap={4}>
        {/* CARDS START */}
        <Stack direction={{ sm: 'row' }} gap={4}>
          {
            // Aquí se debe renderizar el contenido de la vista
            cardsData
              ? cardsData.map((card, index) => (
                  <Fragment key={index}>
                    {kindOfCollection === EKingOfCollection.PSE ||
                    (kindOfCollection === EKingOfCollection.TIENDAS &&
                      index !== 0) ? (
                      <div
                        className={styles.card}
                        style={{
                          backgroundColor: `${card.color}20`,
                          borderLeft: `5px solid ${card.color}`,
                        }}
                      >
                        <Stack
                          direction={{ sm: 'row' }}
                          alignItems={'center'}
                          gap={4}
                        >
                          <Stack gap={1}>
                            <div>
                              <strong>{card.titleValue}: </strong> {card.value}
                            </div>
                            <div>
                              <strong>{card.titleAmount}: </strong>
                              {formatToCOP(card.amount ?? 0)}
                            </div>
                          </Stack>
                        </Stack>
                      </div>
                    ) : null}
                  </Fragment>
                ))
              : null
          }
        </Stack>
        {/* CARDS END */}

        {/* FILTERS START */}
        <Stack
          direction={{ sm: 'row' }}
          justifyContent={'space-between'}
          gap={2}
        >
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={stores}
            sx={{ width: 200 }}
            className={styles.autocomplete}
            size="small"
            value={'Recaudos'}
            onChange={(event, newValue) =>
              setKindOfCollection(String(newValue || ''))
            }
            inputValue={'Recaudos'}
            onInputChange={(event, newInputValue) =>
              navigate(
                `/contabilidad/buscar-archivos-${newInputValue?.toLowerCase()}`,
              )
            }
            renderInput={(params) => <TextField {...params} label="Archivos" />}
          />
          <Stack direction={{ sm: 'row' }} gap={2}>
            <Autocomplete
              value={kindOfCollection}
              onChange={(event, newValue) =>
                setKindOfCollection(String(newValue || ''))
              }
              inputValue={kindOfCollection}
              onInputChange={(event, newInputValue) =>
                setKindOfCollection(newInputValue)
              }
              disablePortal
              id="combo-box-demo"
              options={[EKingOfCollection.PSE, EKingOfCollection.TIENDAS]}
              className={styles.autocomplete}
              size="small"
              sx={{ width: 200 }}
              renderInput={(params) => (
                <TextField {...params} label="Tipo de recaudo" />
              )}
            />

            {kindOfCollection === EKingOfCollection.TIENDAS ? (
              <Fragment>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={[
                    MagicKindOfStore.Nalsani,
                    MagicKindOfStore.Franquicias,
                    MagicKindOfStore.Distribuidores,
                  ]}
                  sx={{ width: 200 }}
                  className={styles.autocomplete}
                  size="small"
                  value={filters.kindOfStore}
                  onChange={(event, value) =>
                    handleFilterChange(
                      { disabled: false, id: 0, label: value || '' },
                      'kindOfStore',
                    )
                  }
                  loading={loadingSelect}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo de tienda"
                      value={filters.kindOfStore}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingSelect ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                {filters.kindOfStore === MagicKindOfStore.Nalsani && (
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={storesNalsani || []}
                    sx={{ width: 200 }}
                    className={styles.autocomplete}
                    size="small"
                    onChange={(event, value) =>
                      handleFilterChange(value as any, 'businessName')
                    }
                    loading={loadingSelect}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tienda"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingSelect ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
                {filters.kindOfStore === MagicKindOfStore.Franquicias && (
                  <Fragment>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={businessName || []}
                      sx={{ width: 200 }}
                      className={styles.autocomplete}
                      size="small"
                      onChange={(event, value) => {
                        console.log(value, 'value')
                        handleFilterChange(value as any, 'businessName')
                      }}
                      loading={loadingSelect}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Franquicia"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingSelect ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />

                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={storeFranchise || []}
                      sx={{ width: 200 }}
                      className={styles.autocomplete}
                      size="small"
                      onChange={(event, value) =>
                        handleFilterChange(value as any, 'store')
                      }
                      loading={loadingSelect}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tiendas"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingSelect ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Fragment>
                )}
                {filters.kindOfStore === MagicKindOfStore.Distribuidores && (
                  <Fragment>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={storesDistributors || []}
                      sx={{ width: 200 }}
                      className={styles.autocomplete}
                      size="small"
                      onChange={(event, value) => {
                        handleFilterChange(value as any, 'businessName')
                      }}
                      loading={loadingSelect}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Distribuidor"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingSelect ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={storeDistributors || []}
                      sx={{ width: 200 }}
                      className={styles.autocomplete}
                      size="small"
                      onChange={(event, value) =>
                        handleFilterChange(value as any, 'store')
                      }
                      loading={loadingSelect}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tiendas"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingSelect ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Fragment>
                )}
              </Fragment>
            ) : null}
            <DateRangePicker
              startDate={filters.startDate ? new Date(filters.startDate) : null}
              endDate={filters.endDate ? new Date(filters.endDate) : null}
              onDateChange={(startDate: Date | null, endDate: Date | null) =>
                handleDateChange(startDate as Date, endDate as Date)
              }
            />
            <button
              onClick={() => handleDownloadExcel()}
              className={styles.buttonDownload}
            >
              <FontAwesomeIcon
                className={styles.iconButton}
                icon={faFileExcel}
              />
            </button>
            <button
              onClick={() => handleSearch()}
              className={styles.searchButton}
            >
              <FontAwesomeIcon
                className={styles.iconButton}
                icon={faMagnifyingGlass}
              />
            </button>
          </Stack>
        </Stack>
        {/* FILTERS END */}
        {/* <pre>{JSON.stringify(filters, null, 2)}</pre> */}
        {kindOfCollection === EKingOfCollection.PSE && (
          <TablePSE
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            headersPSE={headersPSE}
            page={page}
            rows={rowsPSE}
            rowsPerPage={rowsPerPage}
            totalRows={totalRows}
            totalItems={totalItems}
          />
        )}
        {
          // Aquí se debe renderizar el contenido de la vista
          kindOfCollection === EKingOfCollection.TIENDAS && (
            <TableStores
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              headerStores={headersStores}
              page={page}
              rows={rowsStores}
              totalRows={totalRows}
              rowsPerPage={rowsPerPage}
              totalItems={totalItems}
            />
          )
        }
      </Stack>
    </div>
  )
}

export default SearchFilesCollectionsView
