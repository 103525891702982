import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { Column, Data } from './Step2.interface'
import style from './Step2.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const columns: readonly Column[] = [
  { id: 'name', label: 'Nombre', minWidth: 170 },
  { id: 'nameGroup', label: 'Grupo de usuario', minWidth: 100 },
  {
    id: 'permissions',
    label: 'Permisos',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'state',
    label: 'Estado',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'actions',
    label: 'Acciones',
    minWidth: 170,
    align: 'right',
  },
]

function createData(
  name: string,
  nameGroup: string,
  permissions: string,
  state: string,
): Data {
  return { name, nameGroup, permissions, state }
}

const rows = [
  createData('Nombre Dummy', 'Admin', 'Ver, Editar', 'Activo'),
  createData('Nombre Dummy', 'Admin', 'Ver, Editar', 'Activo'),
  createData('Nombre Dummy', 'Admin', 'Ver, Editar', 'Activo'),
  createData('Nombre Dummy', 'Admin', 'Ver, Editar', 'Desactivo'),
  createData('Nombre Dummy', 'Admin', 'Ver, Editar', 'Pendiente'),
  createData('Nombre Dummy', 'Admin', 'Ver, Editar', 'Activo'),
  createData('Nombre Dummy', 'Admin', 'Ver, Editar', 'Activo'),
  createData('Nombre Dummy', 'Admin', 'Ver, Editar', 'Activo'),
  createData('Nombre Dummy', 'Admin', 'Ver, Editar', 'Activo'),
  createData('Nombre Dummy', 'Admin', 'Ver, Editar', 'Activo'),
  createData('Nombre Dummy', 'Admin', 'Ver, Editar', 'Activo'),
  createData('Nombre Dummy', 'Admin', 'Ver, Editar', 'Activo'),
]

/**
 * Renders a table component with pagination and actions.
 *
 * @returns The rendered table component.
 */
export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, e) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={e}>
                      {columns.map((column, index) => {
                        const value = row[column.id as keyof Data]
                        if (column.id === 'actions') {
                          return (
                            <TableCell key={index} align={column.align}>
                              <button className={style.editButton}>
                                <FontAwesomeIcon icon={faPen} /> Editar
                              </button>
                              <button className={style.deleteButton}>
                                <FontAwesomeIcon icon={faTrash} /> Eliminar
                              </button>
                            </TableCell>
                          )
                        }
                        return (
                          <TableCell
                            key={index}
                            align={column.align}
                            className={
                              column.id === 'state' ? style[value] : ''
                            }
                          >
                            {column.id === 'name' ? (
                              <Link to={'/profile/' + value} key={e}>
                                {value}
                              </Link>
                            ) : (
                              value
                            )}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Link to="/profile/add" className={style.newUserButton}>
        Agregar nuevo usuario
      </Link>
    </div>
  )
}
