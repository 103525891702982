import { IPermissions } from 'src/components/Sidebar/Sidebar.Interface'
export const permissions: IPermissions[] = [
  {
    keyName: 'accounting',
    moduleId: 1,
    subModules: [
      {
        keyName: 'uploadFiles',
        moduleId: 1,
        permission: [
          {
            download: ['xlsx', 'pdf'],
          },
          {
            read: ['sales', 'sales_general'],
          },
        ],
      },
      {
        keyName: 'test',
        moduleId: 2,
        subModules: [
          {
            keyName: 'uploadFiles',
            moduleId: 1,
            permission: [
              {
                download: ['xlsx', 'pdf'],
              },
              {
                read: ['sales', 'sales_general'],
              },
            ],
          },
          {
            keyName: 'test2',
            moduleId: 2,
            subModules: [
              {
                keyName: 'uploadFiles',
                moduleId: 1,
                permission: [
                  {
                    download: ['xlsx', 'pdf'],
                  },
                  {
                    read: ['sales', 'sales_general'],
                  },
                ],
              },
              {
                keyName: 'test4',
                moduleId: 2,
              },
              {
                keyName: 'searchFiles',
                moduleId: 3,
                permission: [],
              },
            ],
          },
          {
            keyName: 'searchFiles',
            moduleId: 3,
            permission: [],
          },
        ],
      },
      {
        keyName: 'searchFiles',
        moduleId: 3,
        permission: [],
      },
    ],
  },
  {
    keyName: 'dashboard',
    moduleId: 2,
    subModules: [
      {
        keyName: 'treasury',
        moduleId: 1,
        permission: [
          {
            read: ['upload-file', 'search-sales-files'],
          },
        ],
        subModules: [],
      },
      {
        keyName: 'marketing',
        moduleId: 2,
        subModules: [
          {
            keyName: 'campaigns',
            moduleId: 1,
            permission: [
              {
                read: ['marketing', 'general-kpis', 'security'],
              },
            ],
          },
          {
            keyName: 'discounts',
            moduleId: 2,
            permission: [],
          },
          {
            keyName: 'collections',
            moduleId: 3,
            permission: [
              {
                read: ['collections'],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    keyName: 'iam',
    moduleId: 3,
    subModules: [
      {
        keyName: 'search_users',
        moduleId: 1,
        permission: [
          {
            read: ['Search users'],
          },
        ],
      },
      {
        keyName: 'manage_modules',
        moduleId: 2,
        permission: [
          {
            read: ['Manage modules'],
          },
        ],
      },
    ],
  },
]
