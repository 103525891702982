import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { TextField } from '@mui/material'

interface AlertDialogProps {
  title?: string
  buttonLabel?: string
  agreeLabel?: string
  disagreeLabel?: string
  children?: React.ReactNode
  withConfirmation?: boolean
  nameToDelete?: string
  onAgree?: () => void
  onClose?: () => void
  onDisagree?: () => void
  onOpen?: () => void
}

export default function AlertDialog(props: AlertDialogProps) {
  const [open, setOpen] = React.useState(false)
  const [nombre, setNombre] = React.useState('') // Estado para el valor del nombre

  const handleClickOpen = () => {
    setOpen(true)
    props.onOpen?.()
  }

  const handleClose = () => {
    setOpen(false)
    props.onClose?.()
  }

  const handleAgree = () => {
    props.onAgree?.()
    handleClose()
  }

  const handleDisagree = () => {
    props.onDisagree?.()
    handleClose()
  }

  // Función para manejar cambios en el campo de texto del nombre
  const handleNombreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNombre(event.target.value)
  }

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        {props.buttonLabel || 'Open alert dialog'}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            width: '100%',
            maxWidth: '800px',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {props.title || 'Default Title'}
        </DialogTitle>
        <DialogContent>
          {props.children}
          {props.withConfirmation && (
            <TextField
              sx={{ width: '100%', mt: 2 }}
              id="outlined-basic"
              label="Nombre a eliminar"
              variant="outlined"
              size="small"
              value={nombre} // Asignar el valor del estado al campo de texto
              onChange={handleNombreChange} // Manejar cambios en el campo de texto
              required
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              ml: 1,
            }}
            onClick={handleDisagree}
          >
            {props.disagreeLabel || 'Disagree'}
          </Button>
          {props.withConfirmation && (
            <Button
              onClick={handleAgree}
              autoFocus
              disabled={!nombre || nombre !== props.nameToDelete} // Deshabilitar si no hay nombre o el nombre es diferente
            >
              {props.agreeLabel || 'Agree'}
            </Button>
          )}
          {!props.withConfirmation && (
            <Button onClick={handleAgree} autoFocus>
              {props.agreeLabel || 'Agree'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
