/**
 * El servicio de accounting contiene las funciones necesarias para manejar...
 * @name accountingService
 * @category Servicios Contabilidad
 * @subcategory Accounting
 * @requires fetch
 * @requires getHeaders
 * @requires BASE_URL
 */

import { getHeaders } from 'src/utils/utils'
const BASE_URL_WEBSOCKET = process.env.REACT_APP_WEBSOCKET
// const BASE_URL = process.env.REACT_APP_BASE_URL_LOGIN
const BASE_URL = process.env.REACT_APP_API

/**
 * Genera una URL prefirmada para subir un archivo a AWS con los filtros establecidos
 * @param {Object} data - Los datos para generar la URL prefirmada.
 * @param {File | null} data.file - El archivo a subir.
 * @param {string} data.nameFile - El nombre del archivo.
 * @param {string} data.extensionFile - La extensión del archivo.
 * @param {string} data.startDate - La fecha de inicio para filtrar los datos.
 * @param {string} data.endDate - La fecha de fin para filtrar los datos.
 * @returns {Promise<any>} - La respuesta de la petición como un objeto JSON.
 */
export const loadUrlAws = async (data: {
  file: File | null
  nameFile: 'creditCancellationReport' | 'fga'
  extensionFile: string
  startDate: string
  endDate: string
}): Promise<any> => {
  try {
    const { file, endDate, extensionFile, nameFile, startDate } = data
    const response = await fetch(
      `${BASE_URL}/accounting/generate-presigned-url?name=${nameFile}&ext=${extensionFile}&dateGte=${startDate}&dateLte=${endDate}`,
      {
        method: 'GET',
        headers: getHeaders(),
      },
    )

    const json = await response.json()
    if (!response.ok) {
      throw new Error(json.error.message)
    }
    const responseAWS = await uploadFileSales({
      objectName: json.objectName,
      url: json.url,
      file: file ?? null,
    })
    return responseAWS
  } catch (error) {
    throw error // Lanza el error para que sea capturado en el bloque try/catch donde se llama a uploadFile
  }
}

/**
 * Uploads a file to the server.
 * @param {Object} options - The options for uploading the file.
 * @param {string} options.url - The URL to which the file will be uploaded.
 * @param {string} options.objectName - The name of the object to be uploaded.
 * @param {File | null} options.file - The file to be uploaded.
 * @returns {Promise<Object>} - The response data as a JSON object.
 * @throws {Error} - If there is an error while uploading the file.
 */
export const uploadFileSales = async ({
  url,
  objectName,
  file,
}: {
  url: string
  objectName: string
  file: File | null
}): Promise<any> => {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        ...getHeaders(),
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    })
    if (!response.ok) {
      throw new Error('Error al subir el archivo')
    }
    return {
      message: 'Se subio con exito el archivo',
    }
  } catch (e) {
    console.log(e)
  }
}

/**
 * Loads the data table for accounting.
 *
 * @param {Object} options - The options for loading the data table.
 * @param {string} options.dateGte - The start date for filtering the data.
 * @param {string} options.dateTle - The end date for filtering the data.
 * @param {string} options.kindOfStore - The kind of store for filtering the data.
 * @param {string} options.stores - The stores for filtering the data.
 * @param {number} [options.currentPage=1] - The current page number.
 * @param {number} [options.pageSize=100] - The number of items per page.
 * @param {string} [options.businessName] - The business name for filtering the data.
 * @returns {Promise<Object>} - The response data as a JSON object.
 * @throws {Error} - If there is an error while loading the data.
 */
export const loadDataTable = async ({
  dateGte,
  dateLte,
  kindOfStore,
  stores,
  currentPage = 1,
  pageSize = 100,
  businessName,
  setMessage,
}: {
  dateGte: string
  dateLte: string
  kindOfStore: string
  stores: string
  currentPage?: number
  pageSize?: number
  businessName?: string
  setMessage: (message: string) => void
}): Promise<any> => {
  try {
    if (stores === 'empty' || stores === 'Todas') stores = ''
    const ws = new WebSocket(String(BASE_URL_WEBSOCKET))
    let data = {}
    let jsonResponse = {} as any

    await new Promise((resolve, reject) => {
      ws.onopen = (e) => {
        ws.send(JSON.stringify({ action: 'getConnectionId' }))
        console.log('Conectado')
      }

      ws.onmessage = async (e) => {
        try {
          jsonResponse = JSON.parse(e.data)
          setMessage(
            jsonResponse.data.detail || 'Obteniendo datos de la tienda...',
          )
          if (jsonResponse.data.connectionId) {
            //POST PARA ACTIVAR EL WEBSOCKET
            await fetch(BASE_URL + '/async/accounting/sales', {
              method: 'POST',
              body: JSON.stringify({
                dateGte: dateGte.replaceAll('-', '/'),
                dateLte: dateLte.replaceAll('-', '/'),
                connectionId: jsonResponse.data.connectionId,
                pageSize,
                currentPage: currentPage,
                businessName,
                kindOfStore,
                store: stores,
              }),
              headers: getHeaders(),
            })
          }
          if (jsonResponse.state === 'Finished') {
            console.log(jsonResponse, 'jsonResponse');
            
            data = jsonResponse.data
            resolve(data)
          }
        } catch (error) {
          setMessage('Error al obtener los datos de la tienda')
          reject(error)
        }
      }
    })
    return data
  } catch (error) {
    throw error // Lanza el error para que sea capturado en el bloque try/catch donde se llama a uploadFile
  }
}


//generar archivos prefirmados con aws
export const getAllStores = async (table = '') => {
  try {
    const queryTable = table ? `?table=${table}` : ''
    const response = await fetch(`${BASE_URL}/accounting/stores${queryTable}`, {
      method: 'GET',
      headers: getHeaders(),
    })

    const data = await response.json()
    if (!response.ok) {
      throw new Error(data.error.message)
    }

    return data
  } catch (error) {
    throw error // Lanza el error para que sea capturado en el bloque try/catch donde se llama a uploadFile
  }
}
