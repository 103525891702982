import './AdminPermissions.scss'
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import useLoading from 'src/hooks/useLoading'
import GlobalLoading from 'src/components/Loadings/GlobalLoading'
import {
  createPermission,
  deletePermission,
  getPermission,
  getPermissions,
  updatePermissions,
} from 'src/services/IAM/AdminPermissions/AdminPermissions.service'
import {
  IPermission,
  ITablePermission,
} from 'src/services/IAM/AdminPermissions/AdminPermissions.interface'
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material'
import React from 'react'
import useIsMounted from 'src/hooks/useIsMounted'
import { useAlert } from 'src/contexts/AlertContext'
import { IModule } from 'src/components/Sidebar/Sidebar.Interface'
import { getModules } from 'src/services/IAM/AdminModule/AdminModule.service'
import { IResource } from 'src/services/IAM/AdminResources/AdminResources.interface'
import { getResources } from 'src/services/IAM/AdminResources/AdminResources.service'
import { IAdminApp } from 'src/services/IAM/AdminApps/AdminApp.interface'
import { getApps } from 'src/services/IAM/AdminApps/AdminApp.service'
import AlertDialog from 'src/components/Dialog'

const AdminModule = () => {
  const { showAlert } = useAlert()
  const isMounted = useIsMounted()
  const { isLoading, startLoading, stopLoading } = useLoading()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [permissions, setPermissions] = useState<ITablePermission[] | null>(
    null,
  )
  const [permissionUpdated, setPermissionUpdated] =
    useState<IPermission | null>(null)
  const [modules, setModules] = useState<IModule[] | null>(null)
  const [apps, setApps] = useState<IAdminApp[] | null>(null)
  const [resources, setResources] = useState<IResource[] | null>(null)
  const [selectedApp] = useState<IAdminApp>()
  const [selectedModule] = useState<IModule>()
  const [selectedResource] = useState<IResource>()
  const [newModule] = useState<string>('')
  const [newPermission, setNewPermission] = useState<IPermission>({
    name: '',
    appId: 0,
    moduleId: 0,
    resourceId: 0,
    params: [],
  })

  const [methods, setMethods] = useState<IPermission['params']>([
    {
      permissionId: null,
      method: 'get',
      conditionApp: '',
      conditionModule: '',
      description: '',
      active: false,
    },
    {
      permissionId: null,
      method: 'post',
      conditionApp: '',
      conditionModule: '',
      description: '',
      active: false,
    },
    {
      permissionId: null,
      method: 'put',
      conditionApp: '',
      conditionModule: '',
      description: '',
    },
    {
      permissionId: null,
      method: 'delete',
      conditionApp: '',
      conditionModule: '',
      description: '',
    },
  ])
  // TABLE DATA
  const columns = [
    {
      id: 'permission',
      label: 'Nombre permiso',
      minWidth: 170,
      align: 'right',
    },
    { id: 'appName', label: 'Nombre app', minWidth: 170 },
    { id: 'module', label: 'Nombre modulo', minWidth: 100 },
    {
      id: 'resource',
      label: 'Nombre recurso',
      minWidth: 170,
      align: 'right',
    },
    {
      id: 'actions',
      label: 'Acciones',
      minWidth: 170,
      align: 'right',
    },
  ]

  useEffect(() => {
    const fetchData = async () => {
      try {
        startLoading()
        setApps(await getApps())
        setPermissions(await getPermissions())
        setModules(await getModules({ type: false }))
        setResources(await getResources())
        showAlert('success', 'Módulos cargados correctamente')
      } catch (error) {
        showAlert('error', String(error))
      } finally {
        stopLoading()
      }
    }
    if (!isMounted) return
    fetchData()
  }, [isMounted])

  const handleCreateModule = async (
    e: React.FormEvent<HTMLFormElement>,
    nameNewModule: string,
  ) => {
    try {
      e.preventDefault() // Evitar la actualización de la página
      startLoading()

      await createPermission(newPermission)
      setPermissions(await getPermissions())
      showAlert('success', 'Módulo creado correctamente')
    } catch (error) {
      showAlert('error', String(error))
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // Manejo del cambio de selección
  const handleChangeSelect = (
    event: React.ChangeEvent<{}>,
    newValue: IModule | IAdminApp | IResource | null,
    property: string,
  ) => {
    if (newValue !== null) {
      switch (property) {
        case 'app':
          console.log((newValue as IAdminApp).appId, 'caso app')

          setNewPermission({
            ...newPermission,
            appId: (newValue as IAdminApp).appId,
          })
          break
        case 'module':
          console.log((newValue as IModule).moduleId, 'caso module')

          setNewPermission({
            ...newPermission,
            moduleId: (newValue as IModule).moduleId || 1,
          })
          break
        case 'resource':
          console.log((newValue as IResource).resourceId, 'caso resource')

          setNewPermission({
            ...newPermission,
            resourceId: (newValue as IResource).resourceId || 1,
          })
          break
        default:
          break
      }
      console.log(newPermission, 'newPermission')
    }
  }
  const handleChangeSelectUpdated = (
    event: React.ChangeEvent<{}>,
    newValue: IModule | IAdminApp | IResource | null,
    property: string,
  ) => {
    if (newValue !== null) {
      switch (property) {
        case 'app':
          console.log((newValue as IAdminApp).appId, 'caso app')

          setPermissionUpdated({
            ...permissionUpdated,
            appId: (newValue as IAdminApp).appId,
          })
          break
        case 'module':
          console.log((newValue as IModule).moduleId, 'caso module')

          setPermissionUpdated({
            ...permissionUpdated,
            moduleId: (newValue as IModule).moduleId || 1,
          })
          break
        case 'resource':
          console.log((newValue as IResource).resourceId, 'caso resource')

          setPermissionUpdated({
            ...permissionUpdated,
            resourceId: (newValue as IResource).resourceId || 1,
          })
          break
        default:
          break
      }
      console.log(permissionUpdated, 'permissionUpdated')
    }
  }

  const handleMethodCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    method: 'get' | 'post' | 'put' | 'delete',
  ) => {
    // Primero, actualizar la lista de métodos con el estado 'active' adecuado.
    const updatedMethods = methods?.map((m) => {
      if (m.method === method) {
        return { ...m, active: e.target.checked }
      }
      return m
    })

    // Luego, actualizar los estados basándonos en el nuevo estado 'active' de los métodos.
    setMethods(updatedMethods)

    // Filtrar sólo aquellos métodos que están activos para setear en newPermission.params
    const activeMethods = updatedMethods?.filter((m) => m.active)

    setNewPermission((prevState) => ({
      ...prevState,
      params: activeMethods,
    }))
  }

  const handleInputCondition = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    method: 'get' | 'post' | 'put' | 'delete',
    condition: 'conditionApp' | 'conditionModule' | 'description',
  ) => {
    const updatedMethods = methods?.map((m) => {
      if (m.method === method) {
        return { ...m, [condition]: e.target.value }
      }
      return m
    })

    setMethods(updatedMethods)
    setNewPermission((prevState) => ({
      ...prevState,
      params: updatedMethods?.filter((m) => m.active),
    }))
  }
  const handleMethodCheckboxUpdated = (
    e: React.ChangeEvent<HTMLInputElement>,
    method: 'get' | 'post' | 'put' | 'delete',
  ) => {
    // Primero, actualizar la lista de métodos con el estado 'active' adecuado.
    const updatedMethods = permissionUpdated?.params?.map((m) => {
      console.log(m, 'm')
      if (m.method === method) {
        return { ...m, active: e.target.checked }
      }
      return m
    })
    setPermissionUpdated((prevState: IPermission | null) => ({
      ...prevState,
      params: updatedMethods || [],
    }))
  }

  const handleInputConditionUpdated = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    method: 'get' | 'post' | 'put' | 'delete',
    condition: 'conditionApp' | 'conditionModule' | 'description',
  ) => {
    const updatedMethods = permissionUpdated?.params?.map((m) => {
      if (m.method === method) {
        return { ...m, [condition]: e.target.value }
      }
      return m
    })
    setPermissionUpdated((prevState: IPermission | null) => ({
      ...prevState,
      params: updatedMethods || [],
    }))
  }

  const handleDeletePermission = async (permission: ITablePermission) => {
    try {
      if (!permissionUpdated) return
      startLoading()
      await deletePermission(permission)
      setModules(await getModules())
      showAlert('success', 'Módulo eliminado correctamente')
    } catch (error) {
      showAlert('error', String(error))
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleUpdatePermission = async () => {
    try {
      startLoading()
      const filteredMethods = permissionUpdated?.params?.filter(
        (method) => method.active,
      )
      await updatePermissions({
        ...permissionUpdated,
        params: filteredMethods || [],
      })
      setModules(await getModules())
      showAlert('success', 'Módulo actualizado correctamente')
    } catch (error) {
      showAlert('error', String(error))
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleEditButton = async (permission: ITablePermission) => {
    try {
      startLoading()
      const data = await getPermission(permission)
      const updatedMethods = methods?.map((method, index) => {
        const updatedMethod = (data as IPermission)?.params?.find((um) => {
          return um.method === method.method
        })
        if (updatedMethod) {
          return { ...method, ...updatedMethod, active: true }
        }
        return { ...method, active: false }
      }) as IPermission

      setPermissionUpdated({ ...data, params: updatedMethods }) // Fix: Cast updatedMethods as IPermission[]
      showAlert('success', 'Informacion del modulo cargada correctamente')
    } catch (error) {
      showAlert('error', String(error))
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  return (
    <div className="container-admin-permissions">
      {isLoading && <GlobalLoading />}
      <form className="form" onSubmit={(e) => handleCreateModule(e, newModule)}>
        <p className="form-title">Permiso Nuevo</p>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={modules || []}
          sx={{ width: 200 }}
          className="form-group-input"
          size="small"
          value={selectedModule}
          onChange={(event, newValue) => {
            handleChangeSelect(event, newValue as unknown as IModule, 'module')
          }}
          getOptionLabel={(option) => option.keyName}
          renderInput={(params) => (
            <TextField {...params} label="Modulos y submodulos" />
          )}
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={apps || []}
          sx={{ width: 200 }}
          className="form-group-input"
          size="small"
          value={selectedApp}
          onChange={(event, newValue) => {
            handleChangeSelect(event, newValue as unknown as IAdminApp, 'app')
          }}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} label="Applicaciones" />
          )}
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={resources || []}
          sx={{ width: 200 }}
          className="form-group-input"
          size="small"
          value={selectedResource}
          onChange={(event, newValue) => {
            handleChangeSelect(
              event,
              newValue as unknown as IResource,
              'resource',
            )
          }}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Recursos" />}
        />
        <TextField
          id="outlined-basic"
          label="Nombre permiso nuevo"
          variant="outlined"
          size="small"
          onChange={(e) => {
            setNewPermission({ ...newPermission, name: e.target.value })
          }}
          className="form-group-input"
          required
        />
        <p className="form-title">Métodos</p>
        <Stack gap={2} className="form-methods">
          {methods &&
            methods.map((method, index) => (
              <Stack key={index} gap={2} flexDirection={'row'}>
                <FormGroup key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={method.active}
                        onChange={(e) => handleMethodCheckbox(e, method.method)}
                        name={method.method}
                      />
                    }
                    label={method.method}
                  />
                </FormGroup>
                <TextField
                  id="outlined-basic"
                  label="Condición App"
                  variant="outlined"
                  size="small"
                  className="form-group-input"
                  onChange={(e) => {
                    handleInputCondition(e, method.method, 'conditionApp')
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Condición Módulo"
                  variant="outlined"
                  size="small"
                  className="form-group-input"
                  onChange={(e) => {
                    handleInputCondition(e, method.method, 'conditionModule')
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Descripción"
                  variant="outlined"
                  size="small"
                  className="form-group-input"
                  onChange={(e) => {
                    handleInputCondition(e, method.method, 'description')
                  }}
                />
              </Stack>
            ))}
          <button type="submit" id="" name="" className="form-group-button">
            Crear
          </button>
        </Stack>
      </form>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        sx={{ marginBlock: '20px' }}
      >
        <h2>Todos los modulos</h2>
        <TextField
          id="outlined-basic"
          label="Buscar modulo"
          variant="outlined"
          size="small"
        />
      </Stack>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    align={'center'}
                    style={{ minWidth: column.minWidth }}
                  >
                    <strong>{column.label}</strong>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {permissions &&
                permissions
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, e) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={e}>
                        <TableCell align="center">{row.permission}</TableCell>
                        <TableCell align="center">{row.appName}</TableCell>
                        <TableCell align="center">{row.module}</TableCell>
                        <TableCell align="center">{row.resource}</TableCell>
                        <TableCell align="center">
                          <AlertDialog
                            title={`Editar modulo ${row.permission}`}
                            buttonLabel={'Editar'}
                            disagreeLabel="Cancelar"
                            agreeLabel="Editar"
                            onOpen={() => handleEditButton(row)}
                            onAgree={async () => {
                              handleUpdatePermission()
                            }}
                            onDisagree={() => console.log('Modulo no editado')}
                            children={
                              <form
                                className="form"
                                onSubmit={(e) =>
                                  handleCreateModule(e, newModule)
                                }
                              >
                                <h3>Permiso Nuevo</h3>
                                <Stack
                                  sx={{ my: 2 }}
                                  gap={2}
                                  className="form-methods"
                                >
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={modules || []}
                                    className="form-group-input"
                                    size="small"
                                    value={
                                      modules?.find(
                                        (module) =>
                                          module.moduleId ===
                                          permissionUpdated?.moduleId,
                                      ) || null
                                    }
                                    onChange={(event, newValue) => {
                                      handleChangeSelectUpdated(
                                        event,
                                        newValue as unknown as IModule,
                                        'module',
                                      )
                                    }}
                                    getOptionLabel={(option) => option.keyName}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Modulos y submodulos"
                                      />
                                    )}
                                  />
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={apps || []}
                                    className="form-group-input"
                                    size="small"
                                    value={
                                      apps?.find(
                                        (app) =>
                                          app.appId ===
                                          permissionUpdated?.appId,
                                      ) || null
                                    }
                                    onChange={(event, newValue) => {
                                      handleChangeSelectUpdated(
                                        event,
                                        newValue as unknown as IAdminApp,
                                        'app',
                                      )
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Applicaciones"
                                      />
                                    )}
                                  />
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={resources || []}
                                    className="form-group-input"
                                    size="small"
                                    value={
                                      resources?.find(
                                        (resource) =>
                                          resource.resourceId ===
                                          permissionUpdated?.resourceId,
                                      ) || null
                                    }
                                    onChange={(event, newValue) => {
                                      handleChangeSelectUpdated(
                                        event,
                                        newValue as unknown as IResource,
                                        'resource',
                                      )
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                      <TextField {...params} label="Recursos" />
                                    )}
                                  />
                                  <TextField
                                    id="outlined-basic"
                                    label="Nombre permiso nuevo"
                                    variant="outlined"
                                    size="small"
                                    value={permissionUpdated?.name || ''}
                                    onChange={(e) => {
                                      setPermissionUpdated({
                                        ...permissionUpdated,
                                        name: e.target.value,
                                      })
                                    }}
                                    className="form-group-input"
                                    required
                                  />
                                </Stack>
                                <h3>Métodos</h3>
                                <Stack
                                  sx={{ mt: 2 }}
                                  gap={2}
                                  className="form-methods"
                                >
                                  {permissionUpdated &&
                                    permissionUpdated?.params?.map(
                                      (method, index) => (
                                        <Stack
                                          key={index}
                                          gap={2}
                                          flexDirection={'row'}
                                        >
                                          <FormGroup key={index}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={method.active}
                                                  onChange={(e) =>
                                                    handleMethodCheckboxUpdated(
                                                      e,
                                                      method.method,
                                                    )
                                                  }
                                                  name={method.method + index}
                                                />
                                              }
                                              label={method.method}
                                            />
                                          </FormGroup>
                                          <TextField
                                            id="outlined-basic"
                                            label="Condición App"
                                            variant="outlined"
                                            size="small"
                                            className="form-group-input"
                                            value={
                                              permissionUpdated?.params?.find(
                                                (m) =>
                                                  m.method === method.method,
                                              )?.conditionApp || ''
                                            }
                                            onChange={(e) => {
                                              handleInputConditionUpdated(
                                                e,
                                                method.method,
                                                'conditionApp',
                                              )
                                            }}
                                          />
                                          <TextField
                                            id="outlined-basic"
                                            label="Condición Módulo"
                                            variant="outlined"
                                            size="small"
                                            className="form-group-input"
                                            value={
                                              permissionUpdated?.params?.find(
                                                (m) =>
                                                  m.method === method.method,
                                              )?.conditionModule || ''
                                            }
                                            onChange={(e) => {
                                              handleInputConditionUpdated(
                                                e,
                                                method.method,
                                                'conditionModule',
                                              )
                                            }}
                                          />
                                          <TextField
                                            id="outlined-basic"
                                            label="Descripción"
                                            variant="outlined"
                                            size="small"
                                            className="form-group-input"
                                            value={
                                              permissionUpdated?.params?.find(
                                                (m) =>
                                                  m.method === method.method,
                                              )?.description || ''
                                            }
                                            onChange={(e) => {
                                              handleInputConditionUpdated(
                                                e,
                                                method.method,
                                                'description',
                                              )
                                            }}
                                          />
                                        </Stack>
                                      ),
                                    )}
                                </Stack>
                              </form>
                            }
                          />
                          <AlertDialog
                            buttonLabel={'Eliminar'}
                            title={`¿Estás seguro de eliminar el permiso "${row.permission}"?`}
                            disagreeLabel="Cancelar"
                            agreeLabel="Eliminar"
                            onOpen={() => setPermissionUpdated(row)}
                            onAgree={() => {
                              handleDeletePermission(row)
                            }}
                            nameToDelete={row.permission}
                            withConfirmation
                            onDisagree={() =>
                              console.log('Permiso no eliminado')
                            }
                            children={
                              <div>
                                <p>
                                  Al eliminar este permiso, se eliminarán todos
                                  los submodulos asociados y sus respectivos
                                  permisos
                                </p>
                                <p>
                                  Escriba <strong>{row.permission}</strong> para
                                  confirmar
                                </p>
                              </div>
                            }
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={modules?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

export default AdminModule
