import { Autocomplete, TextField } from '@mui/material'
import React, { Fragment, useState } from 'react'
import { IModule } from 'src/components/Sidebar/Sidebar.Interface'

const SubModuleSelector: React.FC<{
  subModule: IModule
  onSubModuleNameChange: (module: IModule) => void
}> = ({ subModule, onSubModuleNameChange }) => {
  const [selectedSubmodule, setSelectedSubmodule] = useState<IModule | null>(
    null,
  )

  // Manejo del cambio en Autocomplete
  const handleChange = (newValue: IModule | null) => {
    setSelectedSubmodule(newValue)
    if (newValue) {
      onSubModuleNameChange(newValue)
    }
  }

  const selectedSubmodulefinded = selectedSubmodule
    ? subModule?.subModules?.find((sm) => sm.path === selectedSubmodule.path)
    : null

  return (
    <Fragment>
      {subModule?.subModules && subModule?.subModules.length > 0 && (
        <div className="form-group">
          <Autocomplete
            disablePortal
            id="sub-module-selector"
            options={subModule.subModules}
            sx={{ width: 200 }}
            className="form-group-input"
            size="small"
            value={selectedSubmodulefinded}
            onChange={(event, newValue) => handleChange(newValue)}
            getOptionLabel={(option: IModule) => option.keyName}
            renderInput={(params) => (
              <TextField {...params} label="Submódulos" variant="outlined" />
            )}
          />
        </div>
      )}
      {/* Renderizar recursivamente SubModuleSelector para el submódulo seleccionado
      {selectedSubmodulefinded && (
        <SubModuleSelector
          subModule={selectedSubmodulefinded}
          onSubModuleNameChange={onSubModuleNameChange}
        />
      )} */}
    </Fragment>
  )
}

export default SubModuleSelector
