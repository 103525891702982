/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import './AdminResources.scss'
import useLoading from 'src/hooks/useLoading'
import GlobalLoading from 'src/components/Loadings/GlobalLoading'
import { IResource } from 'src/services/IAM/AdminResources/AdminResources.interface'
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { useAlert } from 'src/contexts/AlertContext'
import useIsMounted from 'src/hooks/useIsMounted'
import {
  getResources,
  createResource,
  deleteResource,
  updateResource,
} from 'src/services/IAM/AdminResources/AdminResources.service'
import AlertDialog from 'src/components/Dialog'
const AdminResources = () => {
  const { showAlert } = useAlert()
  const { isLoading, startLoading, stopLoading } = useLoading()
  const isMounted = useIsMounted()
  const [resource, setApp] = useState<IResource>({
    name: '',
    resourceId: 0,
  })
  const [resourceUpdated, serResourceUpdated] = useState<IResource>()
  const [resources, setResources] = useState<IResource[] | null>(null)
  //GET all resources
  useEffect(() => {
    if (!isMounted) return
    const fetchData = async () => {
      try {
        startLoading()
        setResources(await getResources())
        showAlert('success', 'recursos cargadas correctamente')
      } catch (error) {
        showAlert('error', String(error))
      } finally {
        stopLoading()
      }
    }
    fetchData()
  }, [isMounted])

  //handler create resource
  const handleCreateApp = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      startLoading()
      e.preventDefault() // Evitar la actualización de la página
      await createResource(resource)
      showAlert('success', 'Recurso creada correctamente')
      setResources(await getResources())
    } catch (error) {
      console.error('Error en la creación de la recurso', error)
      showAlert('error', 'Error en la creación de la recurso ' + error)
    } finally {
      stopLoading()
    }
  }

  const handleDeleteResource = async (id: number) => {
    try {
      startLoading()
      await deleteResource(id)
      showAlert('success', 'Recurso eliminada correctamente')
      setResources(await getResources())
    } catch (error) {
      console.error('Error en la eliminación de la recurso', error)
      showAlert('error', 'Error en la eliminación de la recurso ' + error)
    } finally {
      stopLoading()
    }
  }

  const handleUpdateResource = async () => {
    try {
      startLoading()
      await updateResource({
        name: resourceUpdated?.name ?? '',
        resourceId: resourceUpdated?.resourceId,
      })
      setResources(await getResources())
      showAlert('success', 'Recurso actualizada correctamente')
    } catch (error) {
      console.error('Error en la actualización de la recurso', error)
      showAlert('error', 'Error en la actualización de la recurso ' + error)
    } finally {
      stopLoading()
    }
  }

  const handleChangeInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (!resourceUpdated) return
    console.log('actualizado')

    serResourceUpdated({ ...resourceUpdated, name: e.target.value })
  }

  return (
    <div className="container-admin-resources">
      {isLoading && <GlobalLoading />}
      <form onSubmit={handleCreateApp} className="form">
        <p className="form-title">Recurso Nueva</p>
        <div className="form-group">
          <TextField
            id="outlined-basic"
            label="Nombre del recurso"
            variant="outlined"
            size="small"
            defaultValue={resource.name}
            onChange={(e) => setApp({ ...resource, name: e.target.value })}
            required
          />
        </div>
        <button type="submit" id="" name="" className="form-group-button">
          Crear
        </button>
      </form>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        sx={{ marginBlock: '20px' }}
      >
        <h2>Todos las recursos</h2>
        <TextField
          id="outlined-basic"
          label="Buscar recurso"
          variant="outlined"
          size="small"
          multiline
          required
        />
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Id</TableCell>
              <TableCell align="right">Nombre</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resources ? (
              resources.map((resource) => {
                return (
                  <TableRow key={resource.resourceId}>
                    <TableCell align="left" scope="row">
                      {resource.resourceId}
                    </TableCell>
                    <TableCell align="right" scope="row">
                      {resource.name}
                    </TableCell>
                    <TableCell align="right">
                      <AlertDialog
                        title={`Editar modulo ${resource.name}`}
                        buttonLabel={'Editar'}
                        disagreeLabel="Cancelar"
                        agreeLabel="Editar"
                        onOpen={() => serResourceUpdated(resource)}
                        onAgree={async () => {
                          handleUpdateResource()
                        }}
                        onDisagree={() => console.log('Modulo no editado')}
                        children={
                          <form>
                            <Box>
                              <TextField
                                sx={{ width: '100%', mt: 2 }}
                                id="outlined-basic"
                                label="Nombre nuevo recurso"
                                variant="outlined"
                                value={resourceUpdated?.name || ''}
                                onChange={(e) => handleChangeInput(e)}
                                required
                              />
                            </Box>
                          </form>
                        }
                      />
                      <AlertDialog
                        buttonLabel={'Eliminar'}
                        title={`¿Estás seguro de eliminar el modulo "${resource.name}"?`}
                        disagreeLabel="Cancelar"
                        agreeLabel="Eliminar"
                        onAgree={() => {
                          handleDeleteResource(Number(resource.resourceId) || 0)
                        }}
                        nameToDelete={resource.name}
                        withConfirmation
                        onDisagree={() => console.log('Modulo no eliminado')}
                        children={
                          <div>
                            <p>
                              Al eliminar este modulo, se eliminarán todos los
                              submodulos asociados y sus respectivos permisos
                            </p>
                            <p>
                              Escriba <strong>{resource.name}</strong> para
                              confirmar
                            </p>
                          </div>
                        }
                      />
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No hay recursos
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default AdminResources
