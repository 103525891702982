/* eslint-disable react-hooks/exhaustive-deps */
import UploadFile from 'src/components/UploadFile/UploadFile'
import styles from './Dashboard.module.scss'
import { useContext, useEffect, useState } from 'react'
import DateRangePicker from 'src/components/DatepickerComponent/DatepickerComponent'
import { DateRange } from 'src/components/DatepickerComponent/DatepickerComponent.interface'
import { Box, Modal } from '@mui/material'
import useLoading from 'src/hooks/useLoading'
import GlobalLoading from 'src/components/Loadings/GlobalLoading'
import Alert from 'src/components/MUI/Alert/Alert'
import { loadUrlAws } from 'src/services/accounting/accountingService'
import { format, subDays } from 'date-fns'
import useIsMounted from 'src/hooks/useIsMounted'
import { startWebSocket } from 'src/services/websocketService'
import SnackbarWithProgress from 'src/components/MUI/SnackbarWithProgress/SnackbarWithProgress'
import { WebSocketContext } from 'src/contexts/WebSocketContext'
import React from 'react'
const DocsView = () => {
  const {
    setMessage,
    setStatus,
    setPercentage,
    setClose,
    close,
    message,
    status,
    percentage,
  } = useContext(WebSocketContext)
  //hook loading
  const { isLoading, startLoading, stopLoading } = useLoading()
  const isMounted = useIsMounted()
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [fgaFile, setFGAFile] = useState<File | null>(null)
  const [reporteAnulacionCreditoFile, setReporteAnulacionCreditoFile] =
    useState<File | null>(null)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertSeverity, setAlertSeverity] = useState<
    'success' | 'error' | 'info' | 'warning'
  >('success')
  const [alertMessage, setAlertMessage] = useState('')
  const [open, setOpen] = useState(false)
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: new Date(),
    endDate: new Date(),
  })

  useEffect(() => {
    // Initialize dateRange with yesterday's date
    const yesterday = subDays(new Date(), 1)
    const formattedYesterday = format(yesterday, 'yyyy/MM/dd')
    setDateRange({
      startDate: formattedYesterday,
      endDate: formattedYesterday,
    })
  }, [])

  const handleOpen = () => setOpen(true)

  const handleClose = () => {
    setOpen(false)
    setReporteAnulacionCreditoFile(null)
  }

  const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
    const formattedStartDate = startDate ? format(startDate, 'yyyy/MM/dd') : ''
    const formattedEndDate = endDate ? format(endDate, 'yyyy/MM/dd') : ''
    setDateRange({ startDate: formattedStartDate, endDate: formattedEndDate })
  }

  const handleUpload = async () => {
    startLoading()
    try {
      const fgaExtension = fgaFile?.name.split('.').pop() ?? '' // Extraer la extensión del nombre del archivo seleccionado
      const file1 = await loadUrlAws({
        file: fgaFile,
        nameFile: 'fga',
        startDate: String(dateRange.startDate),
        endDate: String(dateRange.endDate),
        extensionFile: fgaExtension,
      })
      const reportExtension =
        reporteAnulacionCreditoFile?.name.split('.').pop() ?? '' // Extraer la extensión del nombre del archivo seleccionado
      const file2 = await loadUrlAws({
        file: reporteAnulacionCreditoFile,
        nameFile: 'creditCancellationReport', // Eliminar la extensión del nombre del archivo
        startDate: String(dateRange.startDate),
        endDate: String(dateRange.endDate),
        extensionFile: reportExtension,
      })
      stopLoading()
      setShowSnackbar(true)
      const websocket = await startWebSocket({
        setStatus,
        setMessage,
        setPercentage,
        setClose,
        dateGte: String(dateRange.startDate),
        dateLte: String(dateRange.endDate),
      })
      setFGAFile(null)
      setReporteAnulacionCreditoFile(null)
    } catch (error) {
      console.log('entro en el error')
      setAlertOpen(true)
      setAlertSeverity('error')
      if (error instanceof Error && error.message) {
        setAlertMessage(error.message)
      } else {
        setAlertMessage('Error desconocido')
      }
    } finally {
      handleClose()
      stopLoading()
    }
  }

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    textAlign: 'center',
    p: 4,
  }

  return (
    <div>
      <Alert
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        severity={alertSeverity}
        message={alertMessage}
      />
      {isLoading ? <GlobalLoading /> : null}
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <p>
            ¿Estas seguro que deseas subir el archivo{' '}
            <strong>{fgaFile?.name} </strong>y{' '}
            <strong>{reporteAnulacionCreditoFile?.name}</strong> ?
          </p>
          <div className={styles.containerButtons}>
            <button className={styles.cancel} onClick={handleClose}>
              Cancelar
            </button>
            <button className={styles.upload} onClick={handleUpload}>
              Subir
            </button>
          </div>
        </Box>
      </Modal>
      <div className={styles.content}>
        <div className={styles.header}>
          <h2>Sube tus archivos</h2>
          <DateRangePicker
            startDate={
              dateRange.startDate ? new Date(dateRange.startDate) : null
            }
            endDate={dateRange.endDate ? new Date(dateRange.endDate) : null}
            onDateChange={handleDateChange}
          />
        </div>

        {/* Components for sales */}
        <UploadFile
          label="FGA"
          onFileSelect={setFGAFile}
          selectedFile={fgaFile}
        />
        <UploadFile
          label="Reporte anulación credito"
          onFileSelect={setReporteAnulacionCreditoFile}
          selectedFile={reporteAnulacionCreditoFile}
        />
      </div>
      <button
        disabled={
          reporteAnulacionCreditoFile !== null && fgaFile !== null
            ? false
            : true
        }
        className={styles.buttonSubmit}
        onClick={() => handleOpen()}
      >
        Subir archivos
      </button>
      <SnackbarWithProgress
        message={message}
        onClose={() => setClose(false)}
        open={close}
        status={status}
        duration={3000}
        progress={percentage}
      />
    </div>
  )
}

export default DocsView
