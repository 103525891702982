/* eslint-disable react-hooks/exhaustive-deps */

import styles from './DashboardMenu.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useLocation, useNavigate } from 'react-router-dom'
import { ILocalRoutes } from 'src/components/Sidebar/Sidebar.Interface'

import { AuthContext } from 'src/contexts/AuthContext'
import { useContext, useEffect, useState } from 'react'
import { routes } from './Routes'
import useIsMounted from 'src/hooks/useIsMounted'
import React from 'react'

/**
 * Component for rendering the dashboard menu.
 * @component
 * @returns {JSX.Element} The rendered dashboard menu component.
 */
const DashboardMenu = () => {
  const { permissions, getModules } = useContext(AuthContext)
  const navigate = useNavigate()
  const isMounted = useIsMounted()
  const location = useLocation()
  useEffect(() => {
    if (!isMounted) return
    setModules(searchChildModule(path, getModules(routes, permissions)))
  }, [location.pathname, isMounted])

  /**
   * Searches for child modules based on the given parent path URL and routes.
   * @param {string[]} parentPathUrl - The parent path URL.
   * @param {ILocalRoutes[]} routes - The routes to search in.
   * @param {string} moduleSelected - The selected module.
   * @returns {ILocalRoutes[]} The found child modules.
   */
  const searchChildModule = (
    parentPathUrl: string[],
    routes: ILocalRoutes[],
    moduleSelected = '',
  ): ILocalRoutes[] => {
    if (parentPathUrl.length === 0) {
      return []
    }
    const parent = parentPathUrl[0]
    const parentModule = routes.find((route) => route.title === parent)

    if (!parentModule) {
      return []
    }

    if (parentPathUrl.length === 1) {
      // If there is only one element left in parentPathUrl
      if (moduleSelected === parentModule.title) {
        navigate(parentModule.route)
      }
      if (parentModule.subModules && parentModule.subModules.length > 0) {
        // If the last module has submodules, return those submodules
        return parentModule.subModules
      } else {
        // If the last module does not have submodules, return the current module
        navigate(parentModule.route)
      }
    }

    // If there are still elements in parentPathUrl, continue searching in the submodules of the current parent
    return searchChildModule(
      parentPathUrl.slice(1),
      parentModule.subModules || [],
    )
  }

  const path = location.pathname.split('/').filter((item) => item)
  const [modules, setModules] = useState<ILocalRoutes[]>(
    searchChildModule(path, getModules(routes, permissions)),
  )

  /**
   * Renders the dashboard menu component.
   * @returns {JSX.Element} The rendered dashboard menu component.
   */
  return (
    <>
      <div className={styles.containerCard}>
        {modules?.map((route) => {
          return (
            <div
              onClick={() => {
                setModules(
                  searchChildModule(
                    [...path, route.title],
                    getModules(routes, permissions),
                    route.title,
                  ),
                )
              }}
              key={route.keyName}
              className={`${styles.card} ${route.disabled ? styles.disabled : ''}`}
            >
              {route.icon && (
                <FontAwesomeIcon icon={route.icon} className={styles.icon} />
              )}
              <h3 className={styles.title}>{route.title}</h3>
              <p className={styles.description}>{route.description}</p>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default DashboardMenu
