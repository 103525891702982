import { fetchWithToken, getHeaders } from 'src/utils/utils'
import { IResource } from './AdminResources.interface'

const BASE_URL = process.env.REACT_APP_API_IAM || 'NO_URL'
/**
 * Creates a resource with the given name and resourceId.
 * @param {IResource} param - The resource parameters.
 * @param {string} param.name - The name of the resource.
 * @param {string} param.resourceId - The ID of the resource.
 * @returns {Promise<any>} - A promise that resolves to the created resource data.
 * @throws {Error} - If there is an error creating the resource.
 */
export const createResource = async ({ name, resourceId }: IResource) => {
  try {
    const requestBody = {} as IResource
    if (name?.trim()) requestBody.name = name
    const response = await fetchWithToken(`${BASE_URL}/resource/create`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(requestBody),
    })
    const data = await response.json()
    if (!response.ok && response) {
      throw new Error(data.error.message || 'A ocurrido un error inesperado')
    }
    return data
  } catch (error) {
    console.error('Error en la creación de módulo', error)
    throw error
  }
}

/**
 * Retrieves the resources from the server.
 * @returns {Promise<any>} A promise that resolves to the retrieved resources.
 * @throws {Error} If an error occurs during the retrieval process.
 */
export const getResources = async () => {
  try {
    const response = await fetchWithToken(
      `${BASE_URL}/resource/get-resources`,
      {
        method: 'GET',
        headers: getHeaders(),
      },
    )
    const data = await response.json()
    if (!response.ok && response) {
      throw new Error(data.error.message || 'A ocurrido un error inesperado')
    }
    return data.data
  } catch (error) {
    console.error('Error en la creación de módulo', error)
    throw error
  }
}

/**
 * Deletes a resource by its ID.
 * @param id - The ID of the resource to delete.
 * @returns A Promise that resolves to the JSON response from the server.
 * @throws If the network response is not ok or an error occurs during the deletion process.
 */
export const deleteResource = async (id: number) => {
  try {
    const response = await fetchWithToken(`${BASE_URL}/resource/delete/${id}`, {
      method: 'DELETE',
      headers: getHeaders(),
    })

    if (!response.ok && response) {
      throw new Error('Network response was not ok')
    }

    return await response.json()
  } catch (error) {
    console.error('Error en la eliminación de módulo', error)
    throw error
  }
}

/**
 * Updates a resource with the specified name and resourceId.
 * @param {IResource} resource - The resource object containing the name and resourceId.
 * @returns {Promise<any>} - A promise that resolves to the updated resource data.
 * @throws {Error} - If the network response is not ok or an error occurs during the update.
 */
export const updateResource = async ({ name, resourceId }: IResource) => {
  try {
    const response = await fetchWithToken(
      `${BASE_URL}/resource/update/${resourceId}`,
      {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify({ name }),
      },
    )

    if (!response.ok && response) {
      throw new Error('Network response was not ok')
    }

    return await response.json()
  } catch (error) {
    console.error('Error en la actualización de módulo', error)
    throw error
  }
}
