import { getHeaders } from 'src/utils/utils'
import { IAdminApp } from './AdminApp.interface'

const BASE_URL = process.env.REACT_APP_API_IAM || 'NO_URL'

/**
 * Creates a new admin app.
 * @param {IAdminApp} appData - The data for the admin app.
 * @returns {Promise<any>} - A promise that resolves to the created app data.
 * @throws {Error} - If an error occurs during the creation of the app.
 * @example
 * createApp({ description: 'App description', name: 'App name', type: 'App type' })
 */
export const createApp = async ({ description, name, type }: IAdminApp) => {
  try {
    const response = await fetch(`${BASE_URL}/app/create`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ description, name, type }),
    })
    const data = await response.json()
    if (!response.ok && response) {
      throw new Error(data.error.message || 'A ocurrido un error inesperado')
    }
    return data
  } catch (error) {
    throw error
  }
}

/**
 * Retrieves the list of apps from the server.
 * @returns {Promise<Array<any>>} A promise that resolves to an array of app data.
 * @throws {Error} If there is an error retrieving the apps or if the response is not successful.
 * @example
 * getApps().then(apps => {
 *   console.log(apps); // Array of app data
 * }).catch(error => {
 *   console.error(error);
 * });
 */
export const getApps = async () => {
  try {
    const response = await fetch(`${BASE_URL}/app/get-apps`, {
      method: 'GET',
      headers: getHeaders(),
    })
    const data = await response.json()
    if (!response.ok && response) {
      throw new Error(data.error.message || 'A ocurrido un error inesperado')
    }
    return data.data
  } catch (error) {
    throw error
  }
}

/**
 * Deletes an app with the specified ID.
 * @param {number} id - The ID of the app to delete.
 * @returns {Promise<any>} A Promise that resolves to the JSON response from the server.
 * @throws {Error} If the network response is not ok or an error occurs during the deletion process.
 * @example
 * deleteApp(1).then(response => {
 *   console.log(response); // JSON response from the server
 * }).catch(error => {
 *   console.error(error);
 * });
 */
export const deleteApp = async (id: number) => {
  try {
    const response = await fetch(`${BASE_URL}/app/delete/${id}`, {
      method: 'DELETE',
      headers: getHeaders(),
    })

    if (!response.ok && response) {
      throw new Error('Network response was not ok')
    }

    return await response.json()
  } catch (error) {
    throw error
  }
}

/**
 * Updates an admin app.
 * @param {IAdminApp} app - The admin app object containing the updated information.
 * @returns {Promise<any>} A promise that resolves to the updated admin app data.
 * @throws {Error} If the network response is not ok or an error occurs during the update.
 * @example
 * updateApp({ description: 'Updated description', name: 'Updated name', type: 'Updated type', appId: 1 })
 *   .then(updatedApp => {
 *     console.log(updatedApp); // Updated admin app data
 *   })
 *   .catch(error => {
 *     console.error(error);
 *   });
 */
export const updateApp = async ({
  description,
  name,
  type,
  appId,
}: IAdminApp) => {
  try {
    const response = await fetch(`${BASE_URL}/app/update/${appId}`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify({ description, name, type }),
    })

    if (!response.ok && response) {
      throw new Error('Network response was not ok')
    }

    return await response.json()
  } catch (error) {
    throw error
  }
}
