// src/components/PrivateRoute/PrivateRoute.tsx

import React, { useContext } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'

/**
 * A component that renders a private route based on the authentication status.
 * If the user is authenticated, it renders the child components wrapped in an Outlet component.
 * If the user is not authenticated, it navigates to the login page.
 *
 * @returns The rendered private route component.
 */
const PrivateRoute = () => {
  const { isAuthenticated, isLoading } = useContext(AuthContext)
  const location = useLocation()

  if (isLoading) {
    return <div>Cargando...</div> // O algún indicador de carga
  }

  if (location.pathname === '/') {
    return <Navigate to="/login" />
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />
}

export default PrivateRoute
