// LoginForm.tsx

// Importaciones necesarias
import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import useLoginForm from '../../hooks/useLoginForm'
import logo from '../../assets/images/logo.svg'
import styles from './LoginView.module.scss'
import { login } from '../../services/authService'
import Loader from 'src/components/Loadings/GlobalLoading'
import { UserTypeOption, RollOfWorker } from './LoginView.interface'
import { useCookies } from 'react-cookie'
import { jwtDecode } from 'jwt-decode'
import useIsMounted from 'src/hooks/useIsMounted'
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

// Define the option type for the user types

// Define the options for the user type select
const userTypeOptions: UserTypeOption[] = [
  { value: 'cliente', label: 'Client' },
  { value: 'trabajador', label: 'Backoffice' },
  // Add other options as needed
]

const formatCedula = (value: string): string => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

// Componente LoginForm
const Login = () => {
  const isMounted = useIsMounted()
  const navigate = useNavigate()
  const { setUser, authenticateUser, logoutUser, isAuthenticated } =
    useContext(AuthContext)
  // Puedes usar cualquier spinner de la librería
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedUserType, setSelectedUserType] =
    useState<UserTypeOption | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie] = useCookies(['sessionToken'])
  useEffect(() => {
    // Si el usuario ya está autenticado, redirige al dashboard
    if (isAuthenticated) {
      navigate('/contabilidad')
    }

    // Encuentra la opción de cliente y la asigna al estado
    const defaultUserTypeOption =
      userTypeOptions.find((option) => option.value === 'cliente') ?? null
    setSelectedUserType(defaultUserTypeOption)
  }, [isMounted, isAuthenticated, navigate])

  // Estados para la validez de cédula y contraseña
  const [cedulaValid, setCedulaValid] = useState(true) // Inicialmente válidos
  const [passwordValid, setPasswordValid] = useState(true)
  const [touched, setTouched] = useState(false) // Estado para rastrear interacciones
  // Obteniendo datos y funciones desde el hook y contexto
  const { formData, handleChange, setFormData } = useLoginForm()

  const [isLoading, setIsLoading] = useState(false)

  const handleLogin = async () => {
    setTouched(true) // Marcar formulario como tocado

    // Verificar que ambos campos son válidos antes de proceder
    if (!cedulaValid || !passwordValid) {
      alert('Datos inválidos')
      return
    }

    if (formData.cedula.length < 5 || formData.password.length < 5) {
      alert('Cédula y contraseña deben tener al menos 5 caracteres')
      return
    }

    // Activa el indicador de carga solo después de pasar las validaciones
    setIsLoading(true)
    try {
      const response = await login(
        formData.cedula,
        formData.password,
        RollOfWorker.Worker,
      )

      if (
        response.AuthenticationResult &&
        response.AuthenticationResult.AccessToken
      ) {
        // Llama a authenticateUser con el token obtenido
        authenticateUser(response.AuthenticationResult.AccessToken)
        const user = jwtDecode(response.AuthenticationResult.AccessToken)
        localStorage.setItem(
          'accessToken',
          JSON.stringify(response.AuthenticationResult.AccessToken),
        )
        localStorage.setItem(
          'IdToken',
          JSON.stringify(response.AuthenticationResult.IdToken),
        )
        localStorage.setItem('user', JSON.stringify(user))
        setUser(
          user ?? {
            username: 'Usuario',
            email: '',
          },
        )
        // Redirige al dashboard
        navigate('/contabilidad')
      } else {
        if (response.ChallengeName) {
          setCookie('sessionToken', String(response.Session))
          navigate('/cambiar-contraseña')
        } else {
          alert('Login fallido')
          // Desactiva el indicador de carga si las credenciales son incorrectas
          setIsLoading(false)
        }
      }
    } catch (error) {
      console.error('Error en el login:', error)
      alert('Error en el login')
      logoutUser() // Cierra la sesión si las credenciales son incorrectas
    } finally {
      setIsLoading(false)
    }
  }

  // Manejador de evento para cambio en campo cédula
  const handleCedulaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/\./g, '') // Elimina los puntos
    const isNumeric = /^\d+$/.test(rawValue) // Verifica si el valor es numérico
    const isLengthValid = rawValue.length === 0 || rawValue.length >= 5
    const isValid = isLengthValid
    setCedulaValid(isValid)

    // Aplica el formateo solo si es numérico
    const formattedValue = isNumeric ? formatCedula(rawValue) : rawValue
    setFormData((prevFormData) => ({
      ...prevFormData,
      cedula: formattedValue,
    }))
  }

  // Manejador de evento para cambio en campo contraseña
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isValid = e.target.value.length >= 5
    setPasswordValid(isValid)
    setTouched(true) // Asume que el campo ha sido tocado cuando cambia
    handleChange(e)
  }

  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }

  // Renderizado del componente
  return (
    <div className={styles.containerLogin}>
      <div className={styles.form}>
        {isLoading && (
          <div className={styles.loadingContainer}>
            <Loader />
          </div>
        )}
        <img src={logo} alt="Logo" className={styles.logo} />
        <TextField
          sx={{ mt: '1rem' }}
          id="outlined-basic"
          label="Nombre de usuario"
          variant="outlined"
          name="cedula"
          value={formData.cedula}
          onChange={handleCedulaChange}
          required
          fullWidth
        />
        {/* Mensaje de error se muestra solo si el formulario fue tocado */}
        <Typography
          sx={{ my: 1, color: 'red', textAlign: 'left', width: '100%' }}
        >
          {touched &&
            !cedulaValid &&
            formData.cedula &&
            'Debes ingresar mínimo 5 caracteres'}
        </Typography>
        <FormControl fullWidth sx={{ mt: '1rem' }}>
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>

          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            name="password"
            value={formData.password}
            onChange={handlePasswordChange}
            required
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Contraseña"
          />
        </FormControl>

        {/* Mensaje de error para contraseña */}
        <Typography
          sx={{ my: 1, color: 'red', textAlign: 'left', width: '100%' }}
        >
          {touched &&
            !passwordValid &&
            formData.password &&
            'Debes ingresar mínimo 5 caracteres'}
        </Typography>
        <button
          onClick={handleLogin}
          className={styles.button}
          disabled={isLoading}
        >
          Iniciar sesión
        </button>
      </div>
    </div>
  )
}

// Exportación del componente Login
export default Login
