import { getHeaders } from 'src/utils/utils'

interface FetchDataParams {
  table: string
  startDate?: string
  endDate?: string
  page?: number
  pageSize?: number
  exportExcel?: boolean
}

const BASE_URL = `${process.env.REACT_APP_API}`

export const fetchData = async ({
  table,
  startDate,
  endDate,
  page,
  pageSize,
  exportExcel,
}: FetchDataParams) => {
  try {
    table = table.toLowerCase()
    const response = await fetch(
      //`${BASE_URL}/treasury/sales?dateGte=2022/09/05&dateLte=2022/09/25&currentPage=1&pageSize=5`,
      `${BASE_URL}/treasury/information?dateGte=${startDate}&dateLte=${endDate}&currentPage=${page}&pageSize=${pageSize}&table=${table}`,
      {
        method: 'GET',
        headers: getHeaders(),
      },
    )

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const data = await response.json()

    return data.data
  } catch (error) {
    console.error('Error en la solicitud API:', error)
    throw error
  }
}
