import { useState } from 'react'

/**
 * El servicio de accounting contiene las funciones para cargar la URL prefirmada para subir un archivo a AWS, subir un archivo a AWS, cargar la tabla de datos para accounting, descargar archivos de la tabla, validar si los archivos subieron exitosamente a S3, obtener todas las tiendas y generar archivos prefirmados con AWS.
 * @module
 * @name otroServicio
 * @category Servicios2
 * @subcategory Accounting2
 * @requires fetch
 * @requires getHeaders
 * @requires BASE_URL
 */
const useLoading = () => {
  const [isLoading, setLoading] = useState(false)

  const startLoading = () => setLoading(true)
  const stopLoading = () => setLoading(false)

  return {
    isLoading,
    startLoading,
    stopLoading,
  }
}

export default useLoading
