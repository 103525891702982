import React, { useEffect, useState } from 'react'
import { sendWhatsAppMessage } from '../../services/WhatsAppService'

const FacturasGenerales = () => {
  const [response, setResponse] = useState(null)
  useEffect(() => {
    const companyName = 'bankvision'
    const message = {
      nombre: 'Nombre Dummy Acero',
      numero_destino: 573103292493,
      codigo: '1245',
      url: '******************',
    }

    sendWhatsAppMessage(companyName, message)
      .then((data) => {
        setResponse(data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [])

  return (
    <div>
      <h2>FacturasGenerales</h2>
      <div>
        {response ? (
          <pre>{JSON.stringify(response, null, 2)}</pre>
        ) : (
          'Loading...'
        )}
      </div>
    </div>
  )
}

export default FacturasGenerales
