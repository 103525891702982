import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { Column, Data, DummyData, DataItem } from './TableMui.interface'
import { ChangeEvent, useEffect, useState } from 'react'
import { formatToCOP } from 'src/utils/utils'
import React from 'react'

const columnsEmpty = [
  { id: 'date', label: '' },
  { id: 'currentSale', label: 'Ventas' },
  {
    id: 'samePeriodSale',
    label: 'Anulaciones Mismo Periodo',
  },
  {
    id: 'previousPeriodSale',
    label: 'Anulaciones Periodos Anteriores',
  },
]
const columns: readonly Column[] = [
  { id: 'date', label: 'Fecha', minWidth: 70 },
  { id: 'currentSale', label: '# Ventas', minWidth: 70 },
  { id: 'currentValue', label: 'Monto ', minWidth: 70 },
  {
    id: 'samePeriodSale',
    label: '# Anulaciones',
    minWidth: 70,
    align: 'left',
  },
  {
    id: 'samePeriodValue',
    label: 'Monto ',
    minWidth: 70,
    align: 'left',
  },
  {
    id: 'previousPeriodSale',
    label: '# Anulaciones',
    minWidth: 70,
    align: 'left',
  },
  {
    id: 'previousPeriodValue',
    label: 'Monto ',
    minWidth: 70,
    align: 'left',
  },
]

function createData(
  date: string,
  currentSale: number,
  currentValue: string,
  samePeriodSale: number,
  samePeriodValue: string,
  previousPeriodSale: number,
  previousPeriodValue: string,
): Data {
  return {
    date,
    currentSale,
    currentValue,
    samePeriodSale,
    samePeriodValue,
    previousPeriodSale,
    previousPeriodValue,
  }
}

/**
 * Renders a table component with sticky header and pagination.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {DummyData | null} props.dataTable - The data to be displayed in the table.
 * @returns {JSX.Element} The rendered table component.
 */
export default function StickyHeadTable({
  dataTable,
}: {
  dataTable: DummyData | null
}) {
  const [rows, setRows] = useState<Data[] | null>(null)

  const buildData = (data: DataItem[] | null): Data[] => {
    if (!data) return []
    const arrayBuild = data.map((item) => {
      return createData(
        item.date,
        Number(item.currentSale),
        formatToCOP(Number(item.currentValue)),
        Number(item.samePeriodSale),
        formatToCOP(Number(item.samePeriodValue)),
        Number(item.previousPeriodsSale),
        formatToCOP(Number(item.previousPeriodsValue)),
      )
    })
    setRows(arrayBuild)
    return arrayBuild
  }

  useEffect(() => {
    if (dataTable) {
      buildData(dataTable.rows)
    } else {
      setRows(null)
    }
  }, [dataTable])

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {rows
                  ? columnsEmpty.map((column, index) => (
                      <TableCell
                        key={index}
                        align={'center'}
                        colSpan={index !== 0 ? 2 : 1}
                        style={{ minWidth: 70, fontSize: '12px', zIndex: 0 }}
                      >
                        <strong>{column.label}</strong>
                      </TableCell>
                    ))
                  : null}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {rows ? (
                  columns.map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontSize: '12px',
                      }}
                    >
                      <strong style={{}}>{column.label}</strong>
                    </TableCell>
                  ))
                ) : (
                  <TableCell align={'center'}>
                    <strong>No hay informacion disponible</strong>
                  </TableCell>
                )}
              </TableRow>
              {rows
                ? rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          {columns.map((column, e) => {
                            const value = row[column.id]
                            return (
                              <TableCell key={e} align={column.align}>
                                {value}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          showLastButton
          showFirstButton
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={dataTable?.totalItems ?? 0}
          rowsPerPage={rowsPerPage}
          page={page === 0 ? 1 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}
