/**
 * El servicio de accounting contiene las funciones para cargar la URL prefirmada para subir un archivo a AWS, subir un archivo a AWS, cargar la tabla de datos para accounting, descargar archivos de la tabla, validar si los archivos subieron exitosamente a S3, obtener todas las tiendas y generar archivos prefirmados con AWS.
 * @module
 * @name accountingService
 * @category Servicios Contabilidad
 * @subcategory Accounting
 * @requires fetch
 * @requires getHeaders
 * @requires BASE_URL
 */
import { getHeaders } from 'src/utils/utils'
import { downloadFile } from '../websocketService'
const BASE_URL = process.env.REACT_APP_API
const BASE_URL_WEBSOCKET = process.env.REACT_APP_WEBSOCKET

export interface propsWebSocket {
  dateGte: string
  dateLte: string
  connectionId: string
  currentPage?: number
  pageSize?: number
}

export const getCollectionsStoresWS = async ({
  currentPage,
  dateGte,
  dateLte,
  pageSize,
  businessName,
  kindOfStore,
  store,
  setMessage,
}: {
  dateLte: string
  dateGte: string
  currentPage: number
  pageSize: number
  kindOfStore: string
  businessName?: string
  store: string
  setMessage: (message: string) => void
}) => {
  try {
    const ws = new WebSocket(String(BASE_URL_WEBSOCKET))
    let data = {}
    let jsonResponse = {} as any

    await new Promise((resolve, reject) => {
      ws.onopen = (e) => {
        ws.send(JSON.stringify({ action: 'getConnectionId' }))
        console.log('Conectado')
      }

      ws.onmessage = async (e) => {
        try {
          jsonResponse = JSON.parse(e.data)
          setMessage(
            jsonResponse.data.detail || 'Obteniendo datos de la tienda...',
          )
          if (jsonResponse.data.connectionId) {
            //POST PARA ACTIVAR EL WEBSOCKET
            await fetch(BASE_URL + '/async/accounting/payments-stores', {
              method: 'POST',
              body: JSON.stringify({
                dateGte: dateGte.replaceAll('-', '/'),
                dateLte: dateLte.replaceAll('-', '/'),
                connectionId: jsonResponse.data.connectionId,
                pageSize,
                currentPage: currentPage === 0 ? 1 : currentPage,
                businessName,
                kindOfStore,
                store,
              }),
              headers: getHeaders(),
            })
          }
          if (jsonResponse.state === 'Finished') {
            data = jsonResponse.data
            resolve(data)
          }
        } catch (error) {
          setMessage('Error al obtener los datos de la tienda')
          reject(error)
        }
      }
    })

    console.log('ya termino')
    return data
  } catch (error) {
    throw error
  }
}
export const getCollectionsPSE = async ({
  currentPage,
  dateGte,
  dateLte,
  pageSize,
  setMessage,
}: {
  dateLte: string
  dateGte: string
  currentPage: number
  pageSize: number
  setMessage: (message: string) => void
}) => {
  try {
    const ws = new WebSocket(String(BASE_URL_WEBSOCKET))
    let data = {}
    let jsonResponse = {} as any

    await new Promise((resolve, reject) => {
      ws.onopen = (e) => {
        ws.send(JSON.stringify({ action: 'getConnectionId' }))
        console.log('Conectado')
      }

      ws.onmessage = async (e) => {
        try {
          jsonResponse = JSON.parse(e.data)
          setMessage(
            jsonResponse.data.detail || 'Obteniendo datos de la tienda...',
          )
          if (jsonResponse.data.connectionId) {
            //POST PARA ACTIVAR EL WEBSOCKET
            await fetch(BASE_URL + '/async/accounting/payments-pse', {
              method: 'POST',
              body: JSON.stringify({
                dateGte: dateGte.replaceAll('-', '/'),
                dateLte: dateLte.replaceAll('-', '/'),
                connectionId: jsonResponse.data.connectionId,
                pageSize,
                currentPage: currentPage + 1,
              }),
              headers: getHeaders(),
            })
          }
          if (jsonResponse.state === 'Finished') {
            data = jsonResponse.data
            resolve(data)
          }
        } catch (error) {
          setMessage('Error al obtener los datos de la tienda')
          reject(error)
        }
      }
    })
    return data
  } catch (error) {
    throw error
  }
}

export const downloadCollectionsStores = async ({
  dateGte,
  dateLte,
  kindOfStore,
  store,
  businessName,
  setMessage,
}: {
  dateGte: string
  dateLte: string
  kindOfStore: string
  store?: string
  businessName?: string
  setMessage: (message: string) => void
}) => {
  try {
    const ws = new WebSocket(String(BASE_URL_WEBSOCKET))
    let jsonResponse = {} as any

    await new Promise((resolve, reject) => {
      ws.onopen = (e) => {
        ws.send(JSON.stringify({ action: 'getConnectionId' }))
        console.log('Conectado')
      }

      ws.onmessage = async (e) => {
        try {
          jsonResponse = JSON.parse(e.data)
          setMessage(jsonResponse.data.detail || 'Descargando archivo...')
          if (jsonResponse.data.connectionId) {
            //POST PARA ACTIVAR EL WEBSOCKET
            await fetch(
              BASE_URL + '/async/accounting/payments-stores/download',
              {
                method: 'POST',
                body: JSON.stringify({
                  dateGte: dateGte.replaceAll('-', '/'),
                  dateLte: dateLte.replaceAll('-', '/'),
                  connectionId: jsonResponse.data.connectionId,
                  kindOfStore,
                  businessName,
                  store,
                }),
                headers: getHeaders(),
              },
            )
          }
          if (jsonResponse.state === 'Finished') {
            console.log(jsonResponse.data.url, 'jsonResponse.data.url')

            downloadURL(jsonResponse.data.url)
            resolve(jsonResponse.data)
          }
        } catch (error) {
          setMessage('Error al descargar el archivo')
          reject(error)
        }
      }
    })

    console.log('ya termino')
    return jsonResponse.data
  } catch (error) {
    throw error
  }
}

export const downloadCollectionsPSE = async ({
  dateGte,
  dateLte,
  setMessage,
}: {
  dateGte: string
  dateLte: string
  setMessage: (message: string) => void
}) => {
  try {
    const ws = new WebSocket(String(BASE_URL_WEBSOCKET))
    let jsonResponse = {} as any

    await new Promise((resolve, reject) => {
      ws.onopen = (e) => {
        ws.send(JSON.stringify({ action: 'getConnectionId' }))
        console.log('Conectado')
      }

      ws.onmessage = async (e) => {
        try {
          jsonResponse = JSON.parse(e.data)
          setMessage(jsonResponse.data.detail || 'Descargando archivo...')
          if (jsonResponse.data.connectionId) {
            //POST PARA ACTIVAR EL WEBSOCKET
            await fetch(BASE_URL + '/async/accounting/payments-pse/download', {
              method: 'POST',
              body: JSON.stringify({
                dateGte: dateGte.replaceAll('-', '/'),
                dateLte: dateLte.replaceAll('-', '/'),
                connectionId: jsonResponse.data.connectionId,
              }),
              headers: getHeaders(),
            })
          }
          if (jsonResponse.state === 'Finished') {
            downloadURL(jsonResponse.data.url)
            resolve(jsonResponse.data)
          }
        } catch (error) {
          setMessage('Error al descargar el archivo')
          reject(error)
        }
      }
    })

    console.log('ya termino')
    return jsonResponse.data
  } catch (error) {
    throw error
  }
}

export const downloadURL = async (url: string) => {
  try {
    window.location.href = url
  } catch (error) {
    console.log(error)
  }
}
