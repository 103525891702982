import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ReactComponent as CalendarIcon } from '../../assets/images/Dashboard/General/icons/calendar.svg'
import styles from './DatepickerComponent.module.scss'
import { Input, Stack } from '@mui/material'

interface DatepickerComponentProps {
  startDate: Date | null
  endDate: Date | null
  onDateChange: (startDate: Date | null, endDate: Date | null) => void
}

const DatepickerComponent: React.FC<DatepickerComponentProps> = ({
  startDate,
  endDate,
  onDateChange,
}) => {
  const [internalStartDate, setInternalStartDate] = useState<Date | null>(
    startDate,
  )
  const [internalEndDate, setInternalEndDate] = useState<Date | null>(endDate)

  useEffect(() => {
    setInternalStartDate(startDate)
    setInternalEndDate(endDate)
  }, [startDate, endDate])

  const handleStartDateChange = (date: Date | null) => {
    console.log(date, internalEndDate)

    setInternalStartDate(date)
    onDateChange(date, internalEndDate)
  }

  const handleEndDateChange = (date: Date | null) => {
    setInternalEndDate(date)
    onDateChange(internalStartDate, date)
  }

  return (
    <Stack
      sx={{
        width: '100%',
        maxWidth: '250px',
        border: '1px solid #0004',
        padding: '0 0.8rem',
        borderRadius: '5px',
      }}
      direction="row"
      spacing={2}
      alignItems={'center'}
    >
      <CalendarIcon className={styles.calendarIcon} />
      <DatePicker
        selected={internalStartDate}
        onChange={handleStartDateChange}
        dateFormat="dd/MM/yyyy"
        wrapperClassName={styles.datePickerWrapper}
        customInput={
          <Input
            placeholder="Fecha de inicio"
            size="small"
            sx={{
              width: '100%',
              maxWidth: '100px',
              ':before': {
                borderBottom: 'none',
              },
              input: {
                p: '0',
              },
            }}
            value={
              internalStartDate
                ? internalStartDate.toLocaleDateString('es-ES')
                : ''
            }
          />
        }
      />
      {/* <div style={{ marginBottom: '5px', marginLeft: '3px' }}>|</div> */}
      <DatePicker
        selected={internalEndDate}
        onChange={handleEndDateChange}
        dateFormat="dd/MM/yyyy"
        wrapperClassName={styles.datePickerWrapper}
        customInput={
          <Input
            placeholder="Fecha de fin"
            size="small"
            value={
              internalEndDate ? internalEndDate.toLocaleDateString('es-ES') : ''
            }
            sx={{
              width: '100%',
              maxWidth: '100px',
              ':before': {
                borderBottom: 'none',
              },
              input: {
                p: '0',
              },
            }}
          />
        }
      />
    </Stack>
  )
}

export default DatepickerComponent
