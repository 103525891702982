import styles from './Header.module.scss'
import Logo from 'src/assets/images/logo2.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { Avatar } from '@mui/material'
import { useContext } from 'react'
import { AuthContext } from 'src/contexts/AuthContext'
import React from 'react'

/**
 * Represents the header component of the application.
 *
 * @component
 * @example
 * ```tsx
 * <Header />
 * ```
 */
const Header = () => {
  const { logoutUser, user } = useContext(AuthContext)

  return (
    <div className={styles.header}>
      <Link to="/" className={styles.linkLogo}>
        <img src={Logo} alt="Logo" />
      </Link>
      <div className={styles.containerIcons}>
        <p>Bienvenido, {user?.username ?? 'Usuario'}</p>
        <Avatar
          alt="User icon"
          src="https://www.w3schools.com/howto/img_avatar.png"
        />
        <FontAwesomeIcon
          icon={faSignOut}
          color="#fff"
          onClick={logoutUser}
          className={styles.logout}
        />
      </div>
    </div>
  )
}
export default Header
