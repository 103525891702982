/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import './AdminApps.scss'
import useLoading from 'src/hooks/useLoading'
import GlobalLoading from 'src/components/Loadings/GlobalLoading'
import {
  createApp,
  deleteApp,
  getApps,
  updateApp,
} from 'src/services/IAM/AdminApps/AdminApp.service'
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { IAdminApp } from 'src/services/IAM/AdminApps/AdminApp.interface'
import { useAlert } from 'src/contexts/AlertContext'
import useIsMounted from 'src/hooks/useIsMounted'
import AlertDialog from 'src/components/Dialog'
const AdminApps = () => {
  const { showAlert } = useAlert()
  const { isLoading, startLoading, stopLoading } = useLoading()
  const [appUpdated, setAppUpdated] = useState<IAdminApp>()
  const isMounted = useIsMounted()
  const [app, setApp] = useState<IAdminApp>({
    description: '',
    name: '',
    type: '',
    appId: 0,
  })

  const [apps, setApps] = useState<IAdminApp[] | null>(null)
  //GET all apps
  useEffect(() => {
    if (!isMounted) return
    const fetchData = async () => {
      try {
        startLoading()
        setApps(await getApps())
        showAlert('success', 'Aplicaciones cargadas correctamente')
      } catch (error) {
        showAlert('error', String(error))
      } finally {
        stopLoading()
      }
    }
    fetchData()
  }, [isMounted])

  //handler create app
  const handleCreateApp = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      startLoading()
      e.preventDefault() // Evitar la actualización de la página
      await createApp(app)
      setApps(await getApps())
      showAlert('success', 'Aplicación creada correctamente')
    } catch (error) {
      console.error('Error en la creación de la aplicacion', error)
      showAlert('error', 'Error en la creación de la aplicacion ' + error)
    } finally {
      stopLoading()
    }
  }
  const handleUpdateApp = async () => {
    try {
      startLoading()
      await updateApp(appUpdated as IAdminApp)
      setApps(await getApps())
      showAlert('success', 'Aplicación actualizada correctamente')
    } catch (error) {
      console.error('Error en la actualización de la aplicacion', error)
      showAlert('error', 'Error en la actualización de la aplicacion ' + error)
    } finally {
      stopLoading()
    }
  }
  const handleChangeInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    property: string,
  ) => {
    setAppUpdated({ ...appUpdated, [property]: e.target.value } as IAdminApp)
  }

  const handleDeleteApp = async (app: IAdminApp) => {
    try {
      startLoading()
      await deleteApp(app.appId)
      setApps(await getApps())
      showAlert('success', 'Aplicación eliminada correctamente')
    } catch (error) {
      console.error('Error en la eliminación de la aplicacion', error)
      showAlert('error', 'Error en la eliminación de la aplicacion ' + error)
    } finally {
      stopLoading()
    }
  }
  return (
    <div className="container-admin-apps">
      {isLoading && <GlobalLoading />}
      <form onSubmit={handleCreateApp} className="form">
        <p className="form-title">Apliación Nueva</p>
        <div className="form-group">
          <TextField
            id="outlined-basic"
            label="Nombre de la app"
            variant="outlined"
            size="small"
            defaultValue={app.name}
            onChange={(e) => setApp({ ...app, name: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <TextField
            id="outlined-basic"
            label="Tipo de aplicación"
            variant="outlined"
            size="small"
            defaultValue={app.type}
            onChange={(e) => setApp({ ...app, type: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <TextField
            id="outlined-basic"
            label="Descripción de la app"
            variant="outlined"
            size="small"
            multiline
            defaultValue={app.description}
            onChange={(e) => setApp({ ...app, description: e.target.value })}
            required
          />
        </div>
        <button type="submit" id="" name="" className="form-group-button">
          Crear
        </button>
      </form>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        sx={{ marginBlock: '20px' }}
      >
        <h2>Todos las aplicaciones</h2>
        <TextField
          id="outlined-basic"
          label="Buscar aplicación"
          variant="outlined"
          size="small"
          multiline
          required
        />
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell align="right">Tipo</TableCell>
              <TableCell align="right">Descripción</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apps ? (
              apps.map((app) => {
                return (
                  <TableRow key={app.appId}>
                    <TableCell component="th" scope="row">
                      {app.name}
                    </TableCell>
                    <TableCell align="right">{app.type}</TableCell>
                    <TableCell align="right">{app.description}</TableCell>
                    <TableCell align="right">
                      <AlertDialog
                        title={`Editar app ${app.name}`}
                        buttonLabel={'Editar'}
                        disagreeLabel="Cancelar"
                        agreeLabel="Editar"
                        onOpen={() => setAppUpdated(app)}
                        onAgree={async () => {
                          handleUpdateApp()
                        }}
                        onDisagree={() => console.log('app no editado')}
                        children={
                          <form>
                            <Box>
                              <TextField
                                sx={{ width: '100%', mt: 2 }}
                                id="outlined-basic"
                                label="Nombre app"
                                variant="outlined"
                                value={appUpdated?.name || ''}
                                onChange={(e) => handleChangeInput(e, 'name')}
                                required
                              />
                              <TextField
                                sx={{ width: '100%', mt: 2 }}
                                id="outlined-basic"
                                label="Tipo de app"
                                variant="outlined"
                                value={appUpdated?.type || ''}
                                onChange={(e) => handleChangeInput(e, 'type')}
                                required
                              />
                              <TextField
                                sx={{ width: '100%', mt: 2 }}
                                id="outlined-basic"
                                label="Descripción app"
                                variant="outlined"
                                value={appUpdated?.description || ''}
                                onChange={(e) =>
                                  handleChangeInput(e, 'description')
                                }
                                required
                              />
                            </Box>
                          </form>
                        }
                      />
                      <AlertDialog
                        buttonLabel={'Eliminar'}
                        title={`¿Estás seguro de eliminar el app "${app.name}"?`}
                        disagreeLabel="Cancelar"
                        agreeLabel="Eliminar"
                        onAgree={() => {
                          handleDeleteApp(app)
                        }}
                        nameToDelete={app.name}
                        withConfirmation
                        onDisagree={() => console.log('app no eliminado')}
                        children={
                          <div>
                            <p>
                              Al eliminar este app, se eliminarán todos los
                              subapps asociados y sus respectivos permisos
                            </p>
                            <p>
                              Escriba <strong>{app.name}</strong> para confirmar
                            </p>
                          </div>
                        }
                      />
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No hay aplicaciones
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default AdminApps
