import './AdminGroup.scss'
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import useLoading from 'src/hooks/useLoading'
import GlobalLoading from 'src/components/Loadings/GlobalLoading'
import {
  createGroup,
  getGroups,
  deleteGroup,
  updateGroup,
} from 'src/services/IAM/AdminGroup/AdminGroup.service'
import { IGroup } from 'src/services/IAM/AdminGroup/AdminGroup.interface'
import {
  Autocomplete,
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material'
import React from 'react'
import useIsMounted from 'src/hooks/useIsMounted'
import { useAlert } from 'src/contexts/AlertContext'
import AlertDialog from 'src/components/Dialog'

const AdminGroup = () => {
  const { showAlert } = useAlert()
  const [groups, setGroups] = useState<IGroup[]>([])
  const [newMainGroup, setNewMainGroup] = useState<string>('')
  const [newGroup, setNewGroup] = useState<string>('')
  const { isLoading, startLoading, stopLoading } = useLoading()
  const [page, setPage] = React.useState(0)
  const [groupUpdated, setGroupUpdated] = useState<IGroup>()
  const isMounted = useIsMounted()
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [selectedGroup, setSelectedGroup] = useState<IGroup | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        startLoading()
        setGroups(await getGroups())
        showAlert('success', 'Módulos cargados correctamente')
      } catch (error) {
        showAlert('error', String(error))
      } finally {
        stopLoading()
      }
    }
    if (!isMounted) return
    fetchData()
  }, [isMounted])

  const columns = [
    { id: 'group', label: 'Group', minWidth: 170, align: 'left' },
    { id: 'permission', label: 'Permission', minWidth: 100, align: 'left' },
  ]
  const handleCreateGroup = async (e: React.FormEvent, group: string) => {
    e.preventDefault()
    try {
      startLoading()
      await createGroup({
        name: group,
        permissions: [],
      })
      setGroups(await getGroups())
      showAlert('success', 'Grupo creado correctamente')
    } catch (error) {
      showAlert('error', String(error))
    } finally {
      stopLoading()
    }
  }

  const handleDeleteGroup = async (group: IGroup) => {
    try {
      startLoading()
      await deleteGroup(group.id || 0)
      setGroups(await getGroups())
      showAlert('success', 'Grupo eliminado correctamente')
    } catch (error) {
      showAlert('error', String(error))
    } finally {
      stopLoading()
    }
  }

  const handleUpdateGroups = async () => {
    try {
      startLoading()
      await updateGroup({
        name: groupUpdated?.name || '',
        permissions: [],
      })
      setGroups(await getGroups())
      showAlert('success', 'Grupo actualizado correctamente')
    } catch (error) {
      showAlert('error', String(error))
    } finally {
      stopLoading()
    }
  }

  const handleChangeInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setGroupUpdated((prev: IGroup | undefined) => ({
      ...prev,
      name: e.target.value,
      permissions: [],
    }))
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleChangeSelect = (
    event: React.SyntheticEvent,
    value: IGroup | null,
    type: string,
  ) => {
    if (type === 'app') {
      setSelectedGroup(value)
    }
  }
  return (
    <div className="container-admin-group">
      {isLoading && <GlobalLoading />}
      {/* Dialog confirmar la eliminacion */}

      <form
        onSubmit={(e) => handleCreateGroup(e, newMainGroup)}
        className="form"
      >
        <p className="form-title">GRUPO NUEVO</p>
        <TextField
          id="outlined-basic"
          label="Nombre nuevo grupo"
          variant="outlined"
          size="small"
          onChange={(e) => setNewMainGroup(e.target.value)}
          className="form-group-input"
          required
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={groups || []}
          sx={{ width: 200 }}
          className="form-group-input"
          size="small"
          value={selectedGroup}
          onChange={(event, newValue) => {
            handleChangeSelect(event, newValue as unknown as IGroup, 'app')
          }}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Permisos" />}
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={groups || []}
          sx={{ width: 200 }}
          className="form-group-input"
          size="small"
          value={selectedGroup}
          onChange={(event, newValue) => {
            handleChangeSelect(event, newValue as unknown as IGroup, 'app')
          }}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Usuarios" />}
        />
        <button type="submit" id="" name="" className="form-group-button">
          Crear
        </button>
      </form>
      <form className="form" onSubmit={(e) => handleCreateGroup(e, newGroup)}>
        <p className="form-title">GRUPOS CREADOS</p>
        <TextField
          id="outlined-basic"
          label="Nombre nuevo grupo"
          variant="outlined"
          size="small"
          onChange={(e) => setNewGroup(e.target.value)}
          className="form-group-input"
          required
        />

        <button type="submit" id="" name="" className="form-group-button">
          Crear
        </button>
      </form>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        sx={{ marginBlock: '20px' }}
      >
        <h2>Todos los grupos</h2>
        <TextField
          id="outlined-basic"
          label="Buscar grupo"
          variant="outlined"
          size="small"
        />
      </Stack>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    align={'center'}
                    style={{ minWidth: column.minWidth }}
                  >
                    <strong>{column.label}</strong>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {groups
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, e) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={e}>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">
                        {row &&
                          row.permissions &&
                          row.permissions?.map((permission, i) => (
                            <span>
                              {permission.method}
                              {i + 1 === row.permissions?.length ?? 0
                                ? ' - ' + permission.name
                                : ', '}
                            </span>
                          ))}
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={groups.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

export default AdminGroup
