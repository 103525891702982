/**
 * This file contains the definition of the routes with their corresponding components.
 * Each route is represented by an object with a `path` and a `component`.
 * The `path` represents the URL path for the route, and the `component` represents the React component to render for that route.
 * @module App
 * @category App
 * @subcategory Views
 * @requires React
 * @example
 * return (
 *  <AuthProvider>
 *   <WebSocketProvider>
 *   <Router>
 *   <Routes>
 *  <Route path="/" element={<PrivateRoute />}>
 *  {routesWithLayout.map((route, index) => (
 * <Route
 * key={index}
 * path={route.path}
 * element={<Layout>{route.component}</Layout>}
 * />
 * ))}
 * </Route>
 * <Route
 * path="/contabilidad/test/test2"
 * element={
 * <Layout>
 * <ChangePassword />
 * </Layout>
 * }
 * />
 * <Route path="/login" element={<Login />} />
 * <Route path="/cambiar-contraseña" element={<ChangePassword />} />
 * <Route path="*" element={<h1>Not Found</h1>} />
 * </Routes>
 * </Router>
 * </WebSocketProvider>
 * </AuthProvider>
 * )
 */
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './views/Login/LoginView'
import ChangePassword from './views/ChangePassword/ChangePasswordView'
import DashboardMenu from 'src/components/DashboardMenu/DashboardMenu'
import Layout from './Layouts/Dashboard/Layout'
import PrivateRoute from 'src/components/PrivateRoute/PrivateRoute'
import { AuthProvider } from './contexts/AuthContext'

import Marketing from './views/Marketing/Marketing'
import Treasury from './views/Treasury/TreasuryView'
import Profile from './views/Profile/Profile'
import VentasGenerales from './views/GeneralSales/GeneralSales'
import Docs from './views/Docs/DocsView'
import FacturasGenerales from './views/GeneralInvoices/GeneralInvoices'
import SearchFiles from './views/SearchFilesSales/SearchFilesView'
import SearchFilesCollectionsView from './views/SearchFilesCollections/SearchFilesCollectionsView'
import AdminModule from './views/AdminModule/AdminModule'
import AdminGroups from './views/AdminGroups/AdminGroups'
import AdminPermissions from './views/AdminPermissions/AdminPermissions'
import AdminResources from './views/AdminResources/AdminResources'
import AdminApps from './views/AdminApps/AdminApps'
import AdminUser from './views/AdminUser/AdminUser'
import { WebSocketProvider } from './contexts/WebSocketContext'
import React from 'react'
import { AlertProvider } from './contexts/AlertContext'

/**
 * This file contains the definition of the routes with their corresponding components.
 * Each route is represented by an object with a `path` and a `component`.
 * The `path` represents the URL path for the route, and the `component` represents the React component to render for that route.
 */
const routesWithLayout = [
  { path: '/contabilidad', component: <DashboardMenu /> },
  { path: '/iam', component: <DashboardMenu /> },
  { path: '/iam/administrar-usuarios', component: <AdminUser /> },
  { path: '/iam/administrar-modulos', component: <AdminModule /> },
  { path: '/iam/administrar-aplicaciones', component: <AdminApps /> },
  { path: '/iam/administrar-grupos', component: <AdminGroups /> },
  { path: '/iam/administrar-permisos', component: <AdminPermissions /> },
  { path: '/iam/administrar-recursos', component: <AdminResources /> },
  { path: '/iam/buscar-usuarios', component: <Profile /> },
  { path: '/dashboard', component: <DashboardMenu /> },
  { path: '/dashboard/marketing', component: <Marketing /> },
  { path: '/dashboard/tesoreria', component: <Treasury /> },
  { path: '/dashboard/ventas-generales', component: <VentasGenerales /> },
  { path: '/dashboard/tesoreria', component: <FacturasGenerales /> },
  { path: '/dashboard/subir-archivo', component: <Docs /> },
  { path: '/contabilidad/buscar-archivos-ventas', component: <SearchFiles /> },
  {
    path: '/contabilidad/buscar-archivos-recaudos',
    component: <SearchFilesCollectionsView />,
  },
]
function App() {
  return (
    <AlertProvider>
      <AuthProvider>
        <WebSocketProvider>
          <Router>
            <Routes>
              <Route path="/" element={<PrivateRoute />}>
                {routesWithLayout.map((route, index) => (
                  // Rutas con Layout
                  <Route
                    key={index}
                    path={route.path}
                    element={<Layout>{route.component}</Layout>}
                  />
                ))}
              </Route>{' '}
              {/* Rutas sin Layout */}
              <Route
                path="/contabilidad/test/test2"
                element={
                  <Layout>
                    <ChangePassword />
                  </Layout>
                }
              />
              <Route path="/login" element={<Login />} />
              <Route path="/cambiar-contraseña" element={<ChangePassword />} />
              {/* Ruta no encontrada */}
              <Route path="*" element={<h1>Not Found</h1>} />
            </Routes>
          </Router>
        </WebSocketProvider>
      </AuthProvider>
    </AlertProvider>
  )
}

export default App
