import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styles from './Sidebar.module.scss'
import { ArrowDropDownSharp } from '@mui/icons-material'
import { IconDefinition, IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IPermissions, SidebarProps } from './Sidebar.Interface'

const links = () => {}

/**
 * Renders a sidebar component.
 *
 * @component
 * @param {Object[]} items - The array of sidebar items.
 * @param {Function} onItemSelect - The function to handle item selection.
 * @returns {JSX.Element} The sidebar component.
 */
export default function Sidebar({ items, onItemSelect }: SidebarProps) {
  const [showMenu, setShowMenu] = useState(false)
  const location = useLocation()
  const [currentModule, setCurrentModule] = useState('')

  useEffect(() => {
    setCurrentModule(location.pathname.split('/')[1])
  }, [location])
  const showMenuGlobal = () => {
    setShowMenu(!showMenu)
  }

  return (
    <div
      className={`${styles.containerOverflow}  ${showMenu ? styles.showMenuActive : ''}`}
    >
      <aside className={styles.sidebar}>
        {items.map((item) => (
          <Link
            key={item.keyName}
            to={`${item.route}`}
            className={`${styles.item} ${
              item.title.toLowerCase() === currentModule ? styles.active : ''
            }
            `}
          >
            {item.icon && (
              <FontAwesomeIcon icon={item.icon} className={styles.icon} />
            )}
            <span className={styles.title}>{item.title}</span>
          </Link>
        ))}
        <div className={styles.divider} />
      </aside>
      <div className={styles.showMenu} onClick={showMenuGlobal}>
        <ArrowDropDownSharp />
      </div>
    </div>
  )
}
