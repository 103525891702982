// DashboardCard.tsx
import React from 'react'
import styles from './DashboardCard.module.css'

// Definimos las propiedades esperadas en el objeto data.
interface CardData {
  title: string
  description: string
  icon: JSX.Element
  color?: string // El color es opcional.
}

// Definimos las propiedades esperadas por el componente DashboardCard.
interface Props {
  data: CardData | CardData[] // Puede ser un único objeto de datos o un array de ellos.
}

/**
 * Renders a dashboard card component.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {CardData | CardData[]} props.data - The data to be rendered in the card. It can be a single object or an array of objects.
 * @returns {JSX.Element} The rendered dashboard card component.
 */
const DashboardCard: React.FC<Props> = ({ data }) => {
  const renderCard = (cardData: CardData) => {
    const { icon, color } = cardData
    // Establece un estilo para el contenedor del icono que incluye color y opacidad.
    const iconStyle = {
      color: color || '#47ffa3', // Color por defecto blanco si no se especifica
      opacity: 0.9, // Opacidad por defecto de 1 si no se especifica
    }
    return (
      <div className={styles.card}>
        {/* Aplicamos el color directamente al contenedor del icono */}
        <div className={styles.icon} style={iconStyle}>
          {icon}
        </div>
        <div className={styles.textContainer}>
          <h2>{cardData.title}</h2>
          <p>{cardData.description}</p>
        </div>
      </div>
    )
  }

  return (
    <div>{Array.isArray(data) ? data.map(renderCard) : renderCard(data)}</div>
  )
}

// Exportamos el componente DashboardCard para su uso en otros lugares de la aplicación.
export default DashboardCard
