import './adminModule.scss'
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import useLoading from 'src/hooks/useLoading'
import GlobalLoading from 'src/components/Loadings/GlobalLoading'
import {
  createModule,
  getModules,
  deleteModule,
  updateModule,
} from 'src/services/IAM/AdminModule/AdminModule.service'
import { IModule } from 'src/components/Sidebar/Sidebar.Interface'
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material'
import React from 'react'
import useIsMounted from 'src/hooks/useIsMounted'
import { useAlert } from 'src/contexts/AlertContext'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Column } from 'src/views/AdminModule/AdminModule.interface'
import { Link } from 'react-router-dom'
import TopLevelModuleSelector from './Components/TopLevelModuleSelector'
import SubModuleSelector from './Components/SubModuleSelector'
import AlertDialog from 'src/components/Dialog'
import { IModuleWihoutFormat } from 'src/services/IAM/AdminModule/AdminModule.interface'

const AdminModule = () => {
  const { showAlert } = useAlert()
  const [modules, setModules] = useState<IModule[]>([])
  const [newMainModule, setNewMainModule] = useState<string>('')
  const [newModule, setNewModule] = useState<string>('')
  const { isLoading, startLoading, stopLoading } = useLoading()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [selectedModule, setSelectedModule] = useState<IModule>()
  const [selectedSubmodule, setSelectedSubmodule] = useState<IModule>()
  const [moduleUpdated, setModuleUpdated] = useState<IModule>()
  const isMounted = useIsMounted()
  const [modulesWithoutFormat, setModulesWithoutFormat] = useState<
    IModuleWihoutFormat[]
  >([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        startLoading()
        setModules(await getModules())
        setModulesWithoutFormat(
          await getModules({
            type: false,
          }),
        )
        console.log(modulesWithoutFormat, ' modulesWithoutFormat')

        showAlert('success', 'Módulos cargados correctamente')
      } catch (error) {
        showAlert('error', String(error))
      } finally {
        stopLoading()
      }
    }
    if (!isMounted) return
    fetchData()
  }, [isMounted])
  const handleChangeInput = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (!moduleUpdated) return
    console.log('existe')

    setModuleUpdated({
      ...moduleUpdated,
      keyName: e.target.value,
    })
  }
  const handleCreateModule = async (
    e: React.FormEvent<HTMLFormElement>,
    nameNewModule: string,
  ) => {
    try {
      e.preventDefault() // Evitar la actualización de la página
      startLoading()

      await createModule({
        keyName: nameNewModule,
        path: selectedModule?.path ?? '',
        moduleId: selectedSubmodule?.moduleId ?? 0,
        parentPath: selectedModule?.parentPath ?? '',
      })
      setModules(await getModules())
      showAlert('success', 'Módulo creado correctamente')
    } catch (error) {
      showAlert('error', String(error))
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const handleUpdateModules = async () => {
    try {
      startLoading()
      await updateModule(moduleUpdated as IModule)
      setModules(await getModules())
      showAlert('success', 'Módulo actualizado correctamente')
    } catch (error) {
      showAlert('error', String(error))
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // TABLE DATA
  const columns: readonly Column[] = [
    { id: 'keyName', label: 'KeyName', minWidth: 170 },
    { id: 'path', label: 'path', minWidth: 100 },
    {
      id: 'actions',
      label: 'Acciones',
      minWidth: 170,
      align: 'right',
    },
  ]

  const handleDeleteModule = async (module: IModule) => {
    try {
      if (!moduleUpdated) return
      startLoading()
      await deleteModule(module.moduleId)
      setModules(await getModules())
      showAlert('success', 'Módulo eliminado correctamente')
    } catch (error) {
      showAlert('error', String(error))
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  return (
    <div className="container-admin-module">
      {isLoading && <GlobalLoading />}
      {/* Dialog confirmar la eliminacion */}

      <form
        onSubmit={(e) => handleCreateModule(e, newMainModule)}
        className="form"
      >
        <p className="form-title">MODULO NUEVO</p>
        <TextField
          id="outlined-basic"
          label="Nombre nuevo modulo"
          variant="outlined"
          size="small"
          onChange={(e) => setNewMainModule(e.target.value)}
          className="form-group-input"
          required
        />
        <button type="submit" id="" name="" className="form-group-button">
          Crear
        </button>
      </form>
      <form className="form" onSubmit={(e) => handleCreateModule(e, newModule)}>
        <p className="form-title">MODULOS CREADOS</p>
        <TopLevelModuleSelector
          selectedModule={selectedModule as IModule}
          onModuleChange={(module: IModule) => setSelectedModule(module)}
          modules={modules}
        />
        <SubModuleSelector
          onSubModuleNameChange={(module: IModule) =>
            setSelectedSubmodule(module)
          }
          subModule={
            modules.find((m) => m.path === selectedModule?.path) as IModule
          }
        />
        <TextField
          id="outlined-basic"
          label="Nombre nuevo modulo"
          variant="outlined"
          size="small"
          onChange={(e) => setNewModule(e.target.value)}
          className="form-group-input"
          required
        />
        <button type="submit" id="" name="" className="form-group-button">
          Crear
        </button>
      </form>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        sx={{ marginBlock: '20px' }}
      >
        <h2>Todos los modulos</h2>
        <TextField
          id="outlined-basic"
          label="Buscar modulo"
          variant="outlined"
          size="small"
        />
      </Stack>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <strong>{column.label}</strong>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {modules
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, e) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={e}>
                      {columns.map((column, index) => {
                        if (column.id === 'actions') {
                          return (
                            <TableCell key={index} align={column.align}>
                              <AlertDialog
                                title={`Editar modulo ${row.keyName}`}
                                buttonLabel={'Editar'}
                                disagreeLabel="Cancelar"
                                agreeLabel="Editar"
                                onOpen={() => setModuleUpdated(row)}
                                onAgree={async () => {
                                  handleUpdateModules()
                                }}
                                onDisagree={() =>
                                  console.log('Modulo no editado')
                                }
                                children={
                                  <form>
                                    <Box>
                                      <TextField
                                        sx={{ width: '100%', mt: 2 }}
                                        id="outlined-basic"
                                        label="Nombre nuevo modulo"
                                        variant="outlined"
                                        value={moduleUpdated?.keyName || ''}
                                        onChange={(e) => handleChangeInput(e)}
                                        required
                                      />
                                    </Box>
                                  </form>
                                }
                              />
                              <AlertDialog
                                buttonLabel={'Eliminar'}
                                title={`¿Estás seguro de eliminar el modulo "${row.keyName}"?`}
                                disagreeLabel="Cancelar"
                                agreeLabel="Eliminar"
                                onOpen={() => setModuleUpdated(row)}
                                onAgree={() => {
                                  handleDeleteModule(row)
                                }}
                                nameToDelete={row.keyName}
                                withConfirmation
                                onDisagree={() =>
                                  console.log('Modulo no eliminado')
                                }
                                children={
                                  <div>
                                    <p>
                                      Al eliminar este modulo, se eliminarán
                                      todos los submodulos asociados y sus
                                      respectivos permisos
                                    </p>
                                    <p>
                                      Escriba <strong>{row.keyName}</strong>{' '}
                                      para confirmar
                                    </p>
                                  </div>
                                }
                              />
                            </TableCell>
                          )
                        }
                        return (
                          <TableCell key={index} align={column.align}>
                            {column.id === 'keyName' ? (
                              <span>{row.keyName}</span>
                            ) : (
                              <span>{row.path}</span>
                            )}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={modules.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

export default AdminModule
