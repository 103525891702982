// Marketing.tsx
// Importamos React y useEffect para manejar el ciclo de vida del componente.
import React, { useEffect } from 'react'
// Importamos nuestro componente de tarjeta y el estilo para este componente.
import CardComponent from 'src/components/DashboardCard/DashboardCard'
import styles from './Marketing.module.css'
// Importamos nuestros íconos como componentes de React.
import { ReactComponent as PersonIcon } from '../../assets/images/Marketing/PersonIcon.svg'
import { ReactComponent as ShoppingCartIcon } from '../../assets/images/Marketing/ShoppingCartIcon.svg'

// Definimos las props que nuestro componente Marketing espera recibir.

// Aquí definimos el componente Marketing utilizando la sintaxis de función de flecha.
const Marketing = () => {
  return (
    <main className={styles.container}>
      <div className={styles.content}>
        {/* A continuación, renderizamos múltiples componentes CardComponent con diferentes datos. */}
        {/* Para el icono de personas, aplicamos una clase para cambiar el color si es necesario. */}
        <CardComponent
          data={{
            title: 'Número de personas que realizaron pagos',
            description: '231097',
            icon: <PersonIcon />,
            color: '#628CBB',
          }}
        />

        <CardComponent
          data={{
            title: 'Compraron el mismo día',
            description: '281 - 22.9%',
            icon: <PersonIcon />,
          }}
        />

        <CardComponent
          data={{
            title: 'Compraron el mismo mes',
            description: '3724 - 63.2%',
            icon: <PersonIcon />,
          }}
        />

        {/* Repetimos el componente de tarjeta para diferentes sets de datos. */}
        <CardComponent
          data={{
            title: 'Número de pagos',
            description: '132403',
            icon: <PersonIcon />,
            color: '#B5D700',
          }}
        />

        <CardComponent
          data={{
            title: 'Valor acumulado compras día',
            description: "$4'345.658",
            icon: <ShoppingCartIcon />,
          }}
        />

        <CardComponent
          data={{
            title: 'Valor acumulado compras',
            description: '$4345658',
            icon: <ShoppingCartIcon />,
          }}
        />
      </div>
    </main>
  )
}

// Exportamos el componente para poder usarlo en otros lugares de nuestra aplicación.
export default Marketing
