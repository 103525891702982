import React, { createContext, useContext, useState, ReactNode } from 'react'
import CustomAlert from 'src/components/MUI/Alert/Alert'

// Definiendo las propiedades del estado del alerta
interface AlertState {
  open: boolean
  severity: 'error' | 'warning' | 'info' | 'success'
  message: string
}

// Definiendo las acciones disponibles en el contexto
interface AlertContextType {
  showAlert: (severity: AlertState['severity'], message: string) => void
  hideAlert: () => void
}

// Creando el contexto con un valor inicial por defecto
const AlertContext = createContext<AlertContextType | undefined>(undefined)

// Hook para usar el contexto
export const useAlert = (): AlertContextType => {
  const context = useContext(AlertContext)
  if (context === undefined) {
    throw new Error('useAlert must be used within an AlertProvider')
  }
  return context
}

// Provider component
interface AlertProviderProps {
  children: ReactNode
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alert, setAlert] = useState<AlertState>({
    open: false,
    severity: 'info',
    message: '',
  })

  const showAlert = (severity: AlertState['severity'], message: string) => {
    setAlert({ open: true, severity, message })
  }

  const hideAlert = () => {
    setAlert({ ...alert, open: false })
  }

  return (
    <AlertContext.Provider value={{ showAlert, hideAlert }}>
      <CustomAlert
        open={alert.open}
        onClose={hideAlert}
        severity={alert.severity}
        message={alert.message}
      />
      {children}
    </AlertContext.Provider>
  )
}
