import React, { useEffect, useState } from 'react'
import { sendWhatsAppMessage } from '../../services/WhatsAppService'

const VentasGenerales = () => {
  const [response, setResponse] = useState(null)

  useEffect(() => {
    const companyName = 'solati'
    const GenericMessage = {
      endpoint_path: 'v18.0/206988405832595/messages',
      body: {
        messaging_product: 'whatsapp',
        to: '573103292493',
        type: 'template',
        template: {
          name: 'keypago_pago_pse',
          language: {
            code: 'es',
          },
          components: [
            {
              type: 'body',
              parameters: [
                {
                  type: 'text',
                  text: 'Nombre Dummy Javier Acero Mesa',
                },
              ],
            },
          ],
        },
      },
    }

    sendWhatsAppMessage(companyName, GenericMessage)
      .then((data) => {
        setResponse(data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [])

  return (
    <div>
      <h2>VentasGenerales</h2>
      <div>
        {response ? (
          <pre>{JSON.stringify(response, null, 2)}</pre>
        ) : (
          'Loading...'
        )}
      </div>
    </div>
  )
}

export default VentasGenerales
