/* eslint-disable react-hooks/exhaustive-deps */

import { Fragment, useContext, useEffect, useState } from 'react'
import {
  EKingOfCollection,
  ICardType,
  IDataCards,
  IFilters,
  MagicKindOfStore,
  IDataFilter,
  IRowsPSE,
  IRowsStores,
} from 'src/views/SearchFilesCollections/SearchFilesCollections.interface'
import styles from 'src/views/SearchFilesCollections/SearchFilesCollectionsView.module.scss'
import {
  Autocomplete,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faFileExcel,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons'
import { format, subDays } from 'date-fns'
import DateRangePicker from 'src/components/DatepickerComponent/DatepickerComponent'
import useLoading from 'src/hooks/useLoading'
import {
  getAllStores,
  loadDataTable,
} from 'src/services/accounting/accountingService'
import useIsMounted from 'src/hooks/useIsMounted'
import GlobalLoading from 'src/components/Loadings/GlobalLoading'
import TableMui from 'src/components/TableMUI/TableMUI'
import {
  downloadCollectionsPSE,
  downloadCollectionsStores,
} from 'src/services/accounting/collectionsService'
import { formatToCOP } from 'src/utils/utils'
import { WebSocketContext } from 'src/contexts/WebSocketContext'
import { useNavigate } from 'react-router-dom'
import React from 'react'
import { DummyData } from 'src/components/TableMUI/TableMui.interface'
import { startWebSocketDownload } from 'src/services/websocketService'
import { TableInfo } from './Docs.interface'

const SearchFilesCollectionsView = () => {
  //HOOKS
  const { message, setMessage } = useContext(WebSocketContext)
  const navigate = useNavigate()
  const isMounted = useIsMounted()
  const [loadingSelect, setLoadingSelect] = useState<boolean>(false)
  const { isLoading, startLoading, stopLoading } = useLoading()
  const [page, setPage] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [rowsStores, setRowsStores] = useState<IRowsStores[] | null>(null)
  const [rowsPSE, setRowsPSE] = useState<IRowsPSE[] | null>(null)
  const [storesDistributors, setStoresDistributors] = useState<
    IDataFilter[] | null
  >(null)
  const getData = async () => {
    try {
      let response: any
      setMessage('Cargando data inicial...')
      startLoading()
      setDataModule(null)
      setTableInfo(null)
      if (
        filters.kindOfStore === MagicKindOfStore.Distribuidores ||
        filters.kindOfStore === MagicKindOfStore.Franquicias
      ) {
        response = await loadDataTable({
          dateGte: format(new Date(filters.startDate), 'yyyy/MM/dd'),
          dateLte: format(new Date(filters.endDate), 'yyyy/MM/dd'),
          kindOfStore: filters.kindOfStore,
          setMessage,
          stores: filters.store,
          businessName: filters.businessName,
          currentPage: page + 1,
          pageSize: rowsPerPage,
        })
      }
      if (filters.kindOfStore === MagicKindOfStore.Nalsani) {
        response = await loadDataTable({
          dateGte: format(new Date(filters.startDate), 'yyyy/MM/dd'),
          dateLte: format(new Date(filters.endDate), 'yyyy/MM/dd'),
          kindOfStore: filters.kindOfStore,
          setMessage,
          stores: filters.businessName,
          currentPage: page + 1,
          pageSize: rowsPerPage,
        })
      }
      let rows = (response as { rows: IRowsStores[] })?.rows
      let count = (response as { totalPages?: number })?.totalPages || 0
      let totalItems = (response as { totalItems?: number })?.totalItems || 0
      let dataModule = response as DummyData
      setDataModule(dataModule)
      setRowsStores(rows)
      setTotalRows(count)
      setTotalItems(totalItems)
    } catch (error) {
      setMessage('Error al obtener los datos')
    } finally {
      stopLoading()
    }
  }
  useEffect(() => {
    if (!isMounted) return
    const fetchData = async () => {
      getData()
    }
    fetchData()
  }, [page]) // Este useEffect se ejecutará cada vez que `page` cambie.

  // useEffect(() => {
  //   if (!isMounted) return
  //   const fetchData = async () => {
  //     getData()
  //   }
  //   fetchData()
  // }, [rowsPerPage, isMounted])

  const [dataModule, setDataModule] = useState<DummyData | null>(null)
  const [tableInfo, setTableInfo] = useState<TableInfo | null>(null)
  useEffect(() => {
    setTableInfo(null)
  }, [dataModule])

  const [stores] = useState<string[]>(['Ventas', 'Recaudos'])

  const [kindOfCollection, setKindOfCollection] = useState<string>(
    EKingOfCollection.TIENDAS,
  )
  useEffect(() => {
    setRowsPSE(null)
    setRowsStores(null)
    setDataModule(null)
  }, [kindOfCollection])

  const [filters, setFilters] = useState<IFilters>({
    startDate: String(new Date()),
    endDate: String(new Date()),
    kindOfStore: MagicKindOfStore.Nalsani,
    businessName: '',
    store: '',
  })

  // Efects to filters
  useEffect(() => {
    //LIMPIAR FILTROS
    //AGREGAR TIENDAS DE FRANQUICIA
    setFilters((prevFilters) => ({
      ...prevFilters,
      businessName: '',
      store: '',
    }))
  }, [filters.kindOfStore])

  const [storeDistributors, setStoreDistributors] = useState<
    IDataFilter[] | null
  >(null)

  const [storeFranchise, setStoreFranchise] = useState<IDataFilter[] | null>(
    null,
  )
  useEffect(() => {
    //SETEAR TIENDAS DE LA FRANQUICIA
    setStoreFranchise(null)
    setStoreDistributors(null)
    if (filters.kindOfStore === MagicKindOfStore.Franquicias) {
      const foundItem = businessName?.find(
        (item) => item.label === filters.businessName,
      )
      if (foundItem) {
        setStoreFranchise(foundItem.franchises || null)
      } else {
        setStoreFranchise(null)
      }
    }

    if (filters.kindOfStore === MagicKindOfStore.Nalsani) {
      setStoreFranchise(null)
    }

    if (filters.kindOfStore === MagicKindOfStore.Distribuidores) {
      const foundItem = storesDistributors?.find(
        (item) => item.label === filters.businessName,
      )
      console.log(foundItem, 'foundItem')

      if (foundItem) {
        setStoreDistributors(foundItem.stores || null)
      } else {
        setStoreDistributors(null)
      }
    }
  }, [filters.businessName])
  const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
    const formattedStartDate = startDate
      ? startDate.toISOString().split('T')[0].replaceAll('-', '/')
      : ''
    const formattedEndDate = endDate
      ? endDate.toISOString().split('T')[0].replaceAll('-', '/')
      : ''
    setFilters((prevFilters) => ({
      ...prevFilters,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }))
  }

  const [businessName, setBusinessName] = useState<IDataFilter[] | null>(null)
  const [storesNalsani, setStoresNalsani] = useState<IDataFilter[] | null>(null)
  //handler unico para todos los filtros
  const handleFilterChange = (
    value: {
      id: number
      label: string
      disabled: boolean
    },
    filter: string,
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: value?.label ?? '',
    }))
  }

  // GET STORES
  useEffect(() => {
    if (!isMounted) return
    const fetchData = async () => {
      try {
        setLoadingSelect(true)
        const stores = await getAllStores()
        setBusinessName(stores.data.businessName)
        setStoresNalsani(stores.data.storesNalsani)
        setStoresDistributors(stores.data.distributors)
      } catch (error) {
        console.log(error, 'error')
      } finally {
        setLoadingSelect(false)
      }
    }
    fetchData()
  }, [isMounted])
  const handleSearch = async () => {
    await getData()
  }
  const handleDownloadExcel = async () => {
    try {
      setMessage('Descargando archivo...')
      startLoading()
      if (filters.kindOfStore === MagicKindOfStore.Nalsani) {
        await startWebSocketDownload({
          dateGte: format(new Date(filters.startDate), 'yyyy/MM/dd'),
          dateLte: format(new Date(filters.endDate), 'yyyy/MM/dd'),
          kindOfStore: filters.kindOfStore,
          stores: filters.businessName,
          setMessage,
        })
      }
      if (
        filters.kindOfStore === MagicKindOfStore.Franquicias ||
        filters.kindOfStore === MagicKindOfStore.Distribuidores
      ) {
        await startWebSocketDownload({
          dateGte: format(new Date(filters.startDate), 'yyyy/MM/dd'),
          dateLte: format(new Date(filters.endDate), 'yyyy/MM/dd'),
          kindOfStore: filters.kindOfStore,
          stores: filters.store,
          businessName: filters.businessName,
          setMessage,
        })
      }
    } catch (error) {
      console.log(error)
    } finally {
      stopLoading()
    }
  }
  return (
    <div>
      {isLoading && <GlobalLoading message={message} />}
      <Stack gap={4}>
        {/* TABLE INFO */}
        <Stack direction={{ sm: 'row' }} gap={2}>
          <TableContainer component={Paper}>
            <Table
              sx={{ '& thead th:nth-of-type(1)': { width: '40%' } }}
              size={'small'}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell component={'th'} scope="row">
                    <strong>Ventas</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Total</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Monto</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>keypago</TableCell>
                  <TableCell>{dataModule?.totalSales ?? '-'}</TableCell>
                  <TableCell>
                    {formatToCOP(dataModule?.totalSalesValue || 0) ?? '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Totto</TableCell>
                  <TableCell>{dataModule?.totalSalesTotto ?? '-'}</TableCell>
                  <TableCell>
                    {formatToCOP(dataModule?.totalSalesValueTotto || 0) ?? '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Anulaciones mismo periodo</TableCell>
                  <TableCell>{dataModule?.totalCancellations ?? '-'}</TableCell>
                  <TableCell>
                    {formatToCOP(dataModule?.totalCancellationsValue || 0) ??
                      '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Anulaciones periodos anteriores</TableCell>
                  <TableCell>
                    {dataModule?.totalPastCancellations ?? '-'}
                  </TableCell>
                  <TableCell>
                    {formatToCOP(
                      dataModule?.totalPastCancellationsValue || 0,
                    ) ?? '-'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper}>
            <Table
              sx={{ '& thead th:nth-of-type(1)': { width: '40%' } }}
              size={'small'}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell component={'th'} scope="row">
                    <strong>Diferencias</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Total</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Monto</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Está en keypago y no en Totto</TableCell>
                  <TableCell>
                    {dataModule?.totalIsKpButIsNotTotto ?? '-'}
                  </TableCell>
                  <TableCell>
                    {formatToCOP(dataModule?.amountIsKpButIsNotTotto || 0) ??
                      '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Está en Totto y no en keypago </TableCell>
                  <TableCell>
                    {dataModule?.totalIsNotKpButIsTotto ?? '-'}
                  </TableCell>
                  <TableCell>
                    {formatToCOP(dataModule?.amountIsNotKpButIsTotto || 0) ??
                      '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Está en keypago y está en Totto pero con otro medio de pago
                  </TableCell>
                  <TableCell>
                    {dataModule?.totalIskpIsTottoWithOtherPaymentMethod ?? '-'}
                  </TableCell>
                  <TableCell>
                    {formatToCOP(
                      dataModule?.amountIsKpIsTottoWithOtherPaymentMethod || 0,
                    ) ?? '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Está en keypago y está en Totto con medio de pago keypago
                    pero por otro monto
                  </TableCell>
                  <TableCell>
                    {dataModule?.totalIsKpTottoWithAmountDifferent ?? '-'}
                  </TableCell>
                  <TableCell>
                    {formatToCOP(
                      dataModule?.amountIsKpTottoWithAmountDifferent || 0,
                    ) ?? '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Facturas de keypago que no se pudieron imputar
                  </TableCell>
                  <TableCell>
                    {dataModule?.totalIsKpInvoiceNumberTottoNan ?? '-'}
                  </TableCell>
                  <TableCell>
                    {formatToCOP(
                      dataModule?.amountIsKpInvoiceNumberTottoNan || 0,
                    ) ?? '-'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        {/* TABLE INFO END */}

        {/* FILTERS START */}
        <Stack
          direction={{ sm: 'row' }}
          justifyContent={'space-between'}
          gap={2}
        >
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={stores}
            sx={{ width: 200, textTransform: 'capitalize' }}
            className={styles.autocomplete}
            size="small"
            value={'Ventas'}
            onChange={(event, newValue) =>
              setKindOfCollection(String(newValue || ''))
            }
            inputValue={'Ventas'}
            onInputChange={(event, newInputValue) =>
              navigate(
                `/contabilidad/buscar-archivos-${newInputValue?.toLowerCase()}`,
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ textTransform: 'capitalize' }}
                label="Archivos"
              />
            )}
          />
          <Stack direction={{ sm: 'row' }} gap={2}>
            {kindOfCollection === EKingOfCollection.TIENDAS ? (
              <Fragment>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={[
                    MagicKindOfStore.Nalsani,
                    MagicKindOfStore.Franquicias,
                    MagicKindOfStore.Distribuidores,
                  ]}
                  sx={{ width: 200, textTransform: 'capitalize' }}
                  className={styles.autocomplete}
                  size="small"
                  value={filters.kindOfStore}
                  onChange={(event, value) =>
                    handleFilterChange(
                      { disabled: false, id: 0, label: value || '' },
                      'kindOfStore',
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo de tienda"
                      value={filters.kindOfStore}
                    />
                  )}
                />
                {filters.kindOfStore === MagicKindOfStore.Nalsani && (
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={storesNalsani || []}
                    sx={{ width: 200, textTransform: 'capitalize' }}
                    className={styles.autocomplete}
                    size="small"
                    onChange={(event, value) =>
                      handleFilterChange(value as any, 'businessName')
                    }
                    loading={loadingSelect}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ textTransform: 'capitalize' }}
                        label="Tienda"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingSelect ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
                {filters.kindOfStore === MagicKindOfStore.Franquicias && (
                  <Fragment>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={businessName || []}
                      sx={{ width: 200, textTransform: 'capitalize' }}
                      className={styles.autocomplete}
                      size="small"
                      onChange={(event, value) => {
                        console.log(value, 'value')
                        handleFilterChange(value as any, 'businessName')
                      }}
                      loading={loadingSelect}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ textTransform: 'capitalize' }}
                          label="Franquicia"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingSelect ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />

                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={storeFranchise || []}
                      sx={{ width: 200, textTransform: 'capitalize' }}
                      className={styles.autocomplete}
                      size="small"
                      onChange={(event, value) =>
                        handleFilterChange(value as any, 'store')
                      }
                      loading={loadingSelect}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ textTransform: 'capitalize' }}
                          label="Tiendas"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingSelect ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Fragment>
                )}
                {filters.kindOfStore === MagicKindOfStore.Distribuidores && (
                  <Fragment>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={storesDistributors || []}
                      sx={{ width: 200, textTransform: 'capitalize' }}
                      className={styles.autocomplete}
                      size="small"
                      onChange={(event, value) => {
                        handleFilterChange(value as any, 'businessName')
                      }}
                      loading={loadingSelect}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ textTransform: 'capitalize' }}
                          label="Distribuidor"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingSelect ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={storeDistributors || []}
                      sx={{ width: 200, textTransform: 'capitalize' }}
                      className={styles.autocomplete}
                      size="small"
                      onChange={(event, value) =>
                        handleFilterChange(value as any, 'store')
                      }
                      loading={loadingSelect}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ textTransform: 'capitalize' }}
                          label="Tiendas"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingSelect ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Fragment>
                )}
              </Fragment>
            ) : null}
            <DateRangePicker
              startDate={filters.startDate ? new Date(filters.startDate) : null}
              endDate={filters.endDate ? new Date(filters.endDate) : null}
              onDateChange={(startDate: Date | null, endDate: Date | null) =>
                handleDateChange(startDate as Date, endDate as Date)
              }
            />
            <button
              onClick={() => handleDownloadExcel()}
              className={styles.buttonDownload}
            >
              <FontAwesomeIcon
                className={styles.iconButton}
                icon={faFileExcel}
              />
            </button>
            <button
              onClick={() => handleSearch()}
              className={styles.searchButton}
            >
              <FontAwesomeIcon
                className={styles.iconButton}
                icon={faMagnifyingGlass}
              />
            </button>
          </Stack>
        </Stack>
        {/* FILTERS END */}
        <TableMui dataTable={dataModule} />
      </Stack>
    </div>
  )
}

export default SearchFilesCollectionsView
