import React, { createContext, useState, useContext, ReactNode } from 'react'

interface WebSocketContextProps {
  message: string
  percentage: number
  close: boolean
  status: 'error' | 'info' | 'success' | 'warning'
  setStatus: (status: 'error' | 'info' | 'success' | 'warning') => void
  setMessage: (message: string) => void // Agregar setter para message
  setPercentage: (message: number) => void // Agregar setter para message
  setClose: (close: boolean) => void
}

export const WebSocketContext = createContext<WebSocketContextProps>({
  message: '',
  percentage: 0,
  close: false,
  status: 'info',
  setStatus: () => {},
  setMessage: () => {},
  setPercentage: () => {},
  setClose: () => {},
})

export const WebSocketProvider = ({ children }: { children: ReactNode }) => {
  const [message, setMessage] = useState('')
  const [percentage, setPercentage] = useState(0)
  const [close, setClose] = useState(false)
  const [status, setStatus] = useState<
    'error' | 'info' | 'success' | 'warning'
  >('info')

  return (
    <WebSocketContext.Provider
      value={{
        message,
        percentage,
        close,
        status,
        setStatus,
        setMessage,
        setPercentage,
        setClose,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  )
}
