import React, { useState, useEffect } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import LinearProgress from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import { Slide } from '@mui/material'

const Progress = styled(LinearProgress)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
}))

interface SnackbarWithProgressProps {
  status?: 'error' | 'info' | 'success' | 'warning'
  open: boolean
  message: string
  duration?: number
  onClose: () => void
  progress: number
}

/**
 * A custom Snackbar component with progress bar.
 *
 * @component
 * @example
 * ```tsx
 * <SnackbarWithProgress
 *   open={true}
 *   message="Loading..."
 *   status="info"
 *   duration={3000}
 *   progress={50}
 *   onClose={() => {}}
 * />
 * ```
 *
 * @param {object} props - The component props.
 * @param {boolean} props.open - Whether the Snackbar is open or not.
 * @param {string} props.message - The message to be displayed in the Snackbar.
 * @param {string} [props.status='info'] - The severity status of the Snackbar.
 * @param {number} [props.duration=3000] - The duration in milliseconds for which the Snackbar should be displayed.
 * @param {number} [props.progress=0] - The progress value for the progress bar.
 * @param {() => void} props.onClose - The callback function to be called when the Snackbar is closed.
 *
 * @returns {JSX.Element} The rendered SnackbarWithProgress component.
 */
const SnackbarWithProgress: React.FC<SnackbarWithProgressProps> = ({
  open,
  message,
  status,
  duration = 3000,
  progress,
  onClose,
}) => {
  return (
    <Slide direction="down" in={open}>
      <Snackbar
        open={open}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={onClose} severity={status ?? 'info'}>
          {message}
          <Progress variant="determinate" value={progress ?? 0} />
        </Alert>
      </Snackbar>
    </Slide>
  )
}

export default SnackbarWithProgress
