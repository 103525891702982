import { fetchWithToken, getHeaders } from 'src/utils/utils'
import { IModule } from 'src/components/Sidebar/Sidebar.Interface'

const BASE_URL = process.env.REACT_APP_API_IAM || 'NO_URL'
/**
 * Creates a module with the specified path and key name.
 * @param {IModule} module - The module object containing the path and key name.
 * @returns {Promise<any>} - A promise that resolves to the created module data.
 * @throws {Error} - If an error occurs during the module creation process.
 */
export const createModule = async ({ path, keyName }: IModule) => {
  try {
    const requestBody = {} as IModule
    if (path?.trim()) requestBody.path = path
    if (keyName.trim()) requestBody.keyName = keyName
    const response = await fetchWithToken(`${BASE_URL}/module/create`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(requestBody),
    })
    const data = await response.json()
    if (!response.ok && response) {
      throw new Error(data.error.message || 'A ocurrido un error inesperado')
    }
    return data
  } catch (error) {
    console.error('Error en la creación de módulo', error)
    throw error
  }
}

/**
 * Retrieves the modules based on the specified type.
 * @param options - The options for retrieving the modules.
 * @param options.type - This is whether to format the data with a tree structure.
 * @returns A promise that resolves to the retrieved modules.
 * @throws If there is an error retrieving the modules.
 */
export const getModules = async ({ type = true }: { type?: boolean } = {}) => {
  try {
    const params = type ? '?type=frontend' : ''
    const response = await fetchWithToken(
      `${BASE_URL}/module/get-modules${params}`,
      {
        method: 'GET',
        headers: getHeaders(),
      },
    )
    const data = await response.json()
    if (!response.ok) {
      throw new Error(data.error.message)
    }

    return data.data
  } catch (error) {
    console.error('Error en la creación de módulo', error)
    throw error
  }
}

/**
 * Deletes a module by its ID.
 * @param id - The ID of the module to delete.
 * @returns A Promise that resolves to the deleted module data.
 * @throws If an error occurs during the deletion process.
 */
export const deleteModule = async (id: number) => {
  try {
    const response = await fetchWithToken(`${BASE_URL}/module/delete/${id}`, {
      method: 'DELETE',
      headers: getHeaders(),
    })

    const data = await response.json()
    if (!response.ok) {
      throw new Error(data.error.message || 'A ocurrido un error inesperado')
    }

    return data
  } catch (error) {
    console.error('Error en la eliminación de módulo', error)
    throw error
  }
}

/**
 * Updates a module.
 *
 * @param {IModule} module - The module object containing the necessary information for the update.
 * @returns {Promise<any>} - A promise that resolves to the updated module data.
 * @throws {Error} - If an error occurs during the update process.
 */
export const updateModule = async ({
  path,
  keyName,
  parentPath,
  moduleId,
}: IModule) => {
  try {
    const response = await fetchWithToken(
      `${BASE_URL}/module/update/${moduleId}`,
      {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify({
          path: parentPath,
          keyName,
        }),
      },
    )
    const data = await response.json()
    if (!response.ok) {
      throw new Error(data.error.message || 'A ocurrido un error inesperado')
    }

    return data
  } catch (error) {
    console.error('Error en la actualización de módulo', error)
    throw error
  }
}
