import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import styles from './ChangePasswordView.module.scss'
import logo from '../../assets/images/logo.svg'
import { changePassword, login } from 'src/services/authService'
import { useCookies } from 'react-cookie'
import useLoading from 'src/hooks/useLoading'
import GlobalLoading from 'src/components/Loadings/GlobalLoading'
import { Link, useNavigate } from 'react-router-dom'

interface FormData {
  username: string
  oldPassword: string
  password: string
  repeatPassword: string
  phoneNumber: string
}

function ChangePasswordForm() {
  const [cookies, setCookie, removeCookie] = useCookies(['sessionToken'])
  const { isLoading, startLoading, stopLoading } = useLoading()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>()

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      startLoading()
      const loginResponse = await login(
        data.username,
        data.oldPassword,
        'trabajador',
      )
      if (loginResponse) {
        setCookie('sessionToken', loginResponse.Session, { path: '/' })
      }
      const response = await changePassword({
        userName: data.username,
        newPassword: data.password,
        phoneNumber: data.phoneNumber,
        Session: loginResponse.Session,
      })
      if (response) {
        alert(
          'Contraseña cambiada correctamente por favor inicie sesión con su nueva contraseña',
        )
        navigate('/login')
      }
    } catch (error) {
      alert('error cambiando contraseña ' + error)
    } finally {
      stopLoading()
    }
  }

  return (
    <div className={styles.container}>
      {isLoading ? <GlobalLoading /> : null}
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Link to="/login">
          <img src={logo} alt="Logo" className={styles.logo} />
        </Link>
        <div className={styles.formGroup}>
          <label className={styles.label}>Nombre de Usuario:</label>
          <input
            type="text"
            className={styles.input}
            {...register('username', {
              required: 'El nombre de usuario es obligatorio',
              value: '',
            })}
          />
          {errors.username && (
            <span className={styles.error}>{errors.username.message}</span>
          )}
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Contraseña Asignada:</label>
          <input
            type="password"
            className={styles.input}
            {...register('oldPassword', {
              required: 'La contraseña asignada es obligatoria',
              value: '',
            })}
          />
          {errors.oldPassword && (
            <span className={styles.error}>{errors.oldPassword.message}</span>
          )}
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Nueva Contraseña:</label>
          <input
            type="password"
            className={styles.input}
            {...register('password', {
              required: 'La contraseña es obligatoria',
              value: '',
              minLength: {
                value: 8,
                message: 'La contraseña debe tener al menos 8 caracteres',
              },
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                message:
                  'La contraseña debe cumplir con los requisitos mínimos',
              },
            })}
          />
          {errors.password && (
            <span className={styles.error}>{errors.password.message}</span>
          )}
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Repetir Contraseña:</label>
          <input
            type="password"
            className={styles.input}
            {...register('repeatPassword', {
              required: 'Por favor, repita su contraseña',
              value: '',
              minLength: {
                value: 8,
                message:
                  'La contraseña debe cumplir con los requisitos mínimos',
              },
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                message:
                  'La contraseña debe cumplir con los requisitos mínimos',
              },
              validate: (value) =>
                value === watch('password') || 'Las contraseñas no coinciden',
            })}
          />
          {errors.repeatPassword && (
            <span className={styles.error}>
              {errors.repeatPassword.message}
            </span>
          )}
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Número de Teléfono:</label>
          <input
            type="tel"
            className={styles.input}
            {...register('phoneNumber', {
              required: 'El número de teléfono es obligatorio',
              value: '',
              pattern: {
                value: /^[0-9]{10}$/,
                message: 'El número de teléfono debe tener 10 dígitos',
              },
            })}
          />
          {errors.phoneNumber && (
            <span className={styles.error}>{errors.phoneNumber.message}</span>
          )}
        </div>
        <button type="submit" className={styles.button}>
          Cambiar contraseña
        </button>
      </form>
    </div>
  )
}

export default ChangePasswordForm
