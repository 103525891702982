/* eslint-disable react-hooks/exhaustive-deps */
// src/components/Treasury/Treasury.tsx

import React, { useState, useEffect, useContext } from 'react'
import { fetchData } from '../../services/backoffice/treasuryService'
import DateRangePicker from 'src/components/DatepickerComponent/DatepickerComponent'
import DashboardTable from 'src/components/DashboardTable/DashboardTable'
import {
  faFileExcel,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons'
import useIsMounted from 'src/hooks/useIsMounted'
import useLoading from 'src/hooks/useLoading'
import GlobalLoading from 'src/components/Loadings/GlobalLoading'
import { startDownloadTreasury } from 'src/services/websocketService'
import { WebSocketContext } from 'src/contexts/WebSocketContext'
import SnackbarWithProgress from 'src/components/MUI/SnackbarWithProgress/SnackbarWithProgress'
import { format, set, subDays } from 'date-fns'
import { Autocomplete, Button, Stack, TextField } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Props del componente Treasury, si hay alguna función o dato adicional que se pase
// al componente, se debe añadir aquí

// Tipo para los items de datos, ajustar según la estructura de tus datos
interface IDataItem {
  [key: string]: any
}
// Tipo para el rango de fechas
/**
 * Represents a date range with a start date and an end date.
 */
interface DateRange {
  startDate: string | Date
  endDate: string | Date
}
interface DateRange {
  startDate: string | Date
  endDate: string | Date
}

const Treasury = () => {
  const { isLoading, startLoading, stopLoading } = useLoading()
  const yesterday = subDays(new Date(), 1)
  const formattedYesterday = format(yesterday, 'yyyy/MM/dd')
  const isMounted = useIsMounted()
  const {
    setMessage,
    setStatus,
    setPercentage,
    setClose,
    close,
    status,
    message,
    percentage,
  } = useContext(WebSocketContext)
  // Estado para los datos actuales mostrados en la tabla
  const [currentData, setCurrentData] = useState<IDataItem[]>([])

  // Estado para la página actual en la paginación
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRecords, setTotalRecords] = useState(0)
  // Estado para el rango de fechas seleccionado
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: formattedYesterday,
    endDate: formattedYesterday,
  })
  // CARDS TABLE
  const cards = [
    {
      title: 'Ventas',
      color: '#FFC107',
    },
    {
      title: 'Pagos',
      color: '#4CAF50',
    },
    {
      title: 'Anulaciones',
      color: '#F44336',
    },
    {
      title: 'Reversos',
      color: '#2196F3',
    },
  ]
  const [table, setValue] = React.useState<string>(cards[0].title)
  const [inputValue, setInputValue] = React.useState('')

  const [activeTable, setActiveTable] = useState('ventas') // Estado para mantener la tabla activa

  // Número de registros por página, ajustar según necesidad
  const recordsPerPage = 25

  // Cambiar las fechas solo cuando se seleccionen fechas nuevas en el DateRangePicker
  const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
    if (startDate && endDate) {
      const formattedStartDate = startDate
        .toISOString()
        .split('T')[0]
        .replaceAll('-', '/')
      const formattedEndDate = endDate
        .toISOString()
        .split('T')[0]
        .replaceAll('-', '/')
      setDateRange({ startDate: formattedStartDate, endDate: formattedEndDate })
    }
  }

  // Manejador para el clic del botón, que incluye la lógica para llamar a la API
  const handleButtonClick = async (table: string, page?: number) => {
    try {
      setActiveTable(table)
      setCurrentPage(page || 0)
      setTotalRecords(0)
      setCurrentData([])
      startLoading()
      const pageNumber = page || currentPage
      const apiData = await fetchData({
        table: table.toLowerCase(),
        pageSize: recordsPerPage,
        page: pageNumber, // Usar el número de página actualizado
        startDate: String(dateRange.startDate),
        endDate: String(dateRange.endDate),
      })
      setTotalRecords(apiData.totalPages)
      setCurrentData(apiData.rows as IDataItem[])
    } catch (error) {
      console.error('Error al cargar datos:', error)
    } finally {
      stopLoading()
    }
  }

  const handleButtonDownload = async () => {
    try {
      startLoading()
      await startDownloadTreasury({
        setMessage,
        setPercentage,
        setClose,
        setStatus,
        dateGte: String(dateRange.startDate),
        dateLte: String(dateRange.endDate),
        table: activeTable,
      })
    } catch (error) {
      console.log(error, 'error')
    } finally {
      stopLoading()
    }
  }

  // Manejador para la primera página
  const handleFirstPageButtonClick = () => {
    setCurrentPage(1)
    handleButtonClick(activeTable, 1)
  }

  // Manejador para la última página
  const handleLastPageButtonClick = () => {
    setCurrentPage(totalRecords)
    handleButtonClick(activeTable, totalRecords)
  }

  // Manejador para la página anterior
  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
    handleButtonClick(activeTable, currentPage - 1)
  }

  // Manejador para la página siguiente
  const nextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalRecords))
    handleButtonClick(activeTable, currentPage + 1)
  }
  return (
    <div className="container-treasury">
      {isLoading ? <GlobalLoading /> : null}
      <SnackbarWithProgress
        message={message}
        onClose={() => setClose(false)}
        open={close}
        status={status}
        duration={3000}
        progress={percentage}
      />
      <Stack
        direction="row"
        spacing={2}
        justifyContent={'space-between'}
        sx={{ mb: 2 }}
      >
        <Autocomplete
          value={table}
          onChange={(event: any, newValue: string | null) => {
            setValue(newValue || 'ventas')
          }}
          size="small"
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue)
          }}
          id="controllable-states-demo"
          options={cards.map((card) => card.title)}
          getOptionLabel={(option) => option}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Tabla" />}
        />
        <Stack direction="row" spacing={2}>
          <DateRangePicker
            startDate={new Date(dateRange.startDate)}
            endDate={new Date(dateRange.endDate)}
            onDateChange={handleDateChange}
          />
          <Button
            variant="outlined"
            onClick={() => handleButtonDownload}
            sx={{
              backgroundColor: 'white',
              color: '#1e163b',
              border: '1px solid #0004',
            }}
          >
            <FontAwesomeIcon icon={faFileExcel} size="xl" />
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleButtonClick(table, 1)}
            sx={{
              backgroundColor: '#1e163b',
              color: 'white',
            }}
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" />
          </Button>
        </Stack>
      </Stack>
      <DashboardTable
        data={currentData}
        prevPage={() => prevPage()}
        nextPage={() => nextPage()}
        currentPage={currentPage}
        count={totalRecords}
        recordsPerPage={recordsPerPage}
        handleFirstPage={() => handleFirstPageButtonClick()}
        handleLastPage={() => handleLastPageButtonClick()}
      />
    </div>
  )
}
export default Treasury
