import React from 'react'
import styles from './GlobalLoading.module.scss'
/**
 * Renders a global loading component with an optional message.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.message] - The optional message to display.
 * @returns {JSX.Element} The rendered GlobalLoading component.
 */
const GlobalLoading = ({ message }: { message?: string }) => {
  return (
    <div className={styles.fullScreenLoading}>
      <div className={styles.loadingSpinner}></div>
      <div className={styles.loadingMessage}>{message}</div>
    </div>
  )
}

export default GlobalLoading
