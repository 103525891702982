import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
} from '@mui/material'
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  FirstPage,
  LastPage,
} from '@mui/icons-material'
import React, { useEffect, useState } from 'react'

type TableDataItem = {
  [key: string]: any
}

interface DashboardTableProps {
  data: TableDataItem[]
  currentPage: number
  nextPage?: () => void
  prevPage?: () => void
  handleFirstPage?: () => void
  handleLastPage?: () => void
  count: number
  recordsPerPage?: number
}

/**
 * DashboardTable component displays a table with pagination and customizable rows per page.
 *
 * @component
 * @example
 * return (
 *   <DashboardTable
 *     data={data}
 *     currentPage={currentPage}
 *     nextPage={nextPage}
 *     prevPage={prevPage}
 *     handleFirstPage={handleFirstPage}
 *     handleLastPage={handleLastPage}
 *     count={count}
 *     recordsPerPage={10}
 *   />
 * )
 *
 * @param {Object[]} data - The data to be displayed in the table.
 * @param {number} currentPage - The current page number.
 * @param {Function} nextPage - The function to handle the next page action.
 * @param {Function} prevPage - The function to handle the previous page action.
 * @param {Function} handleFirstPage - The function to handle the first page action.
 * @param {Function} handleLastPage - The function to handle the last page action.
 * @param {number} count - The total number of records.
 * @param {number} [recordsPerPage=10] - The number of records to display per page.
 *
 * @returns {JSX.Element} The rendered DashboardTable component.
 */
const DashboardTable: React.FC<DashboardTableProps> = ({
  data,
  currentPage,
  nextPage,
  prevPage,
  handleFirstPage,
  handleLastPage,
  count,
  recordsPerPage = 10,
}) => {
  const [page, setPage] = useState(currentPage - 1)
  const [rowsPerPage, setRowsPerPage] = useState(recordsPerPage)

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage)
  }

  useEffect(() => {
    setPage(currentPage - 1)
  }, [currentPage])

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table>
          <TableHead>
            {data && data.length ? (
              <TableRow>
                {Object.keys(data[0] || {}).map((key, index) => (
                  <TableCell sx={{ textTransform: 'capitalize' }} key={index}>
                    <strong>{key}</strong>
                  </TableCell>
                ))}
              </TableRow>
            ) : null}
          </TableHead>
          <TableBody>
            {data && data.length ? (
              data.map((item, index) => (
                <TableRow key={index}>
                  {Object.values(item).map((value, valueIndex) => (
                    <TableCell
                      key={valueIndex}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {String(value).toLowerCase()}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align={'center'}>
                  <strong>No hay datos</strong>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component={Paper}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={({ onPageChange }) => (
          <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
              onClick={(event) => {
                onPageChange(event, 0)
                handleFirstPage && handleFirstPage()
              }}
              disabled={page === 0}
              aria-label="first page"
            >
              <FirstPage />
            </IconButton>
            <IconButton
              onClick={(event) => {
                onPageChange(event, page - 1)
                prevPage && prevPage()
              }}
              disabled={page === 0}
              aria-label="previous page"
            >
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton
              onClick={(event) => {
                onPageChange(event, page + 1)
                nextPage && nextPage()
              }}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="next page"
            >
              <KeyboardArrowRight />
            </IconButton>
            <IconButton
              onClick={(event) => {
                onPageChange(
                  event,
                  Math.max(0, Math.ceil(count / rowsPerPage) - 1),
                )
                handleLastPage && handleLastPage()
              }}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="last page"
            >
              <LastPage />
            </IconButton>
          </Box>
        )}
      />
    </Paper>
  )
}

export default DashboardTable
