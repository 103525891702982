/**
  @description Contiene las funciones para la autenticación de usuarios.
 */

import { getHeaders } from 'src/utils/utils'

// Importando módulos necesarios.

const BASE_URL_LOGIN = process.env.REACT_APP_BASE_URL_LOGIN || 'NO_URL'
const BASE_URL = process.env.REACT_APP_BASE_URL || 'NO_URL'

//login user
export const login = async (
  username: string,
  password: string,
  userType: string,
) => {
  const data = {
    AuthFlow: 'USER_PASSWORD_AUTH',
    UserType: userType,
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password,
    },
  }

  try {
    console.log(BASE_URL, 'BASE_URL')

    const response = await fetch(`${BASE_URL_LOGIN}/user/generate-token`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
    })

    if (!response.ok && response) {
      console.log(response?.body, 'response?.body')
      throw new Error('Network response was not ok')
    }

    return await response.json()
  } catch (error) {
    console.error('Error en la autenticación', error)
    throw error
  }
}

// Tu función changePassword
/**
 * Changes the password for a user.
 * @param {Object} params - The parameters for changing the password.
 * @param {string} params.userName - The username of the user.
 * @param {string} params.newPassword - The new password for the user.
 * @param {string} params.phoneNumber - The phone number of the user.
 * @param {string} params.Session - The session token for the user.
 * @returns {Promise<Object>} - A promise that resolves to the response from the server.
 * @throws {Error} - If the network response is not ok or an error occurs during the request.
 * @example // Change the password for a user
 * try {
 *     const response = await changePassword({
 *    userName: 'user123',
 *    newPassword: 'newPassword123',
 *    phoneNumber: '1234567890',
 *    Session: '
 * })
 *    console.log(response)
 * } catch (error) {
 *     console.error(error)
 *  }
 */
export const changePassword = async ({
  userName,
  newPassword,
  phoneNumber,
  Session,
}: {
  userName: string
  newPassword: string
  phoneNumber: string
  Session: string
}): Promise<any> => {
  try {
    console.log(BASE_URL, 'BASE_URL')

    // Correct URL
    // 'https://cognito-idp.us-east-1.amazonaws.com/',
    // api.credifactory.co/user/update-use
    const response = await fetch(`${BASE_URL_LOGIN}/user/update-user`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        ChallengeName: 'NEW_PASSWORD_REQUIRED',
        ClientId: process.env.REACT_APP_CLIENTID,
        Session: Session,
        ChallengeResponses: {
          USERNAME: userName,
          NEW_PASSWORD: newPassword,
          'userAttributes.phone_number': '+57' + phoneNumber,
        },
      }),
    })
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return await response.json()
  } catch (error) {
    console.log(error)
    throw error
  }
}
