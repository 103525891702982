import { GenericMessage } from '../interfaces/index.interface' // Import the GenericMessage interface

const apiBase = 'https://meta.credifactory.co'
const apiKeys = {
  solati: process.env.REACT_APP_META_SOLATI,
  bankvision: process.env.REACT_APP_META_BANKVISION,
}
type ApiKey = keyof typeof apiKeys

function isApiKey(key: any): key is ApiKey {
  return key in apiKeys
}

/**
 * Sends a WhatsApp message using the provided company name and message.
 * @param company - The name of the company.
 * @param message - The message to be sent.
 * @returns A Promise that resolves to the response data from the API.
 * @throws An error if the provided company name is not a valid key or if there is an error sending the message.
 */
export const sendWhatsAppMessage = async (
  company: string,
  message: GenericMessage,
) => {
  if (!isApiKey(company)) {
    throw new Error(
      `The provided company name "${company}" is not a valid key.`,
    )
  }
  try {
    const apiEndpoint = `${apiBase}/${company}`
    const apiKey = apiKeys[company]
    const response = await fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiKey}`,
      },
      body: JSON.stringify(message),
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error sending WhatsApp message:', error)
    throw error
  }
}
