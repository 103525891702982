import { ILocalRoutes } from '../Sidebar/Sidebar.Interface'
import {
  faUpload,
  faSearch,
  faFileInvoiceDollar,
  faHandHoldingDollar,
  faChartSimple,
  faChartPie,
  faShieldHalved,
  faCogs,
  faChartLine,
  faFileAlt,
  faUsers,
  faHome,
  faCube,
  faShapes,
  faCodeMerge,
  faPersonChalkboard,
  faLayerGroup,
} from '@fortawesome/free-solid-svg-icons'
/**
 * Represents the routes configuration for the DashboardMenu component.
 */
export const routes: ILocalRoutes[] = [
  {
    keyName: 'iam',
    icon: faCogs,
    disabled: false,
    title: 'iam',
    route: '/iam',
    subModules: [
      {
        keyName: 'search-users',
        title: 'Buscar usuarios',
        icon: faSearch,
        description: 'Busca usuarios por nombre, correo o documento',
        route: '/iam/buscar-usuarios',
        disabled: false,
      },
      {
        keyName: 'create-users',
        title: 'Crear Usuarios',
        icon: faUsers,
        description: 'Crea, modifica o elimina usuarios existentes',
        route: '/iam/administrar-usuarios',
        disabled: false,
      },
      {
        keyName: 'manage-modules',
        title: 'Administrar módulos',
        icon: faCodeMerge,
        description: 'Crea, modifica o elimina módulos existentes',
        route: '/iam/administrar-modulos',
        disabled: false,
      },
      {
        keyName: 'manage-apps',
        title: 'Administrar aplicaciones',
        icon: faShapes,
        description: 'Crea, modifica o elimina aplicaciones existentes',
        route: '/iam/administrar-aplicaciones',
        disabled: false,
      },
      {
        keyName: 'manage-resources',
        title: 'Administrar recursos',
        icon: faCube,
        description: 'Crea, modifica o elimina recursos existentes',
        route: '/iam/administrar-recursos',
        disabled: false,
      },
      {
        keyName: 'manage-permissions',
        title: 'Administrar permisos',
        icon: faPersonChalkboard,
        description: 'Crea, modifica o elimina permisos existentes',
        route: '/iam/administrar-permisos',
        disabled: false,
      },
      {
        keyName: 'manage-groups',
        title: 'Administrar grupos',
        icon: faLayerGroup,
        description: 'Crea, modifica o elimina grupos existentes',
        route: '/iam/administrar-grupos',
        disabled: false,
      },
    ],
  },
  {
    keyName: 'dashboard',
    icon: faChartLine,
    title: 'dashboard',
    route: '/dashboard',
    subModules: [
      {
        keyName: 'marketing',
        title: 'Marketing',
        description: 'KPIs de tráfico, leads, conversiones, campañas etc.',
        icon: faChartSimple,
        disabled: true,
        route: '/contabilidad/marketing',
      },
      {
        keyName: 'general-kpis',
        title: 'KPIs Generales',
        description: 'KPIs de tráfico, leads, conversiones, campañas etc',
        icon: faChartPie,
        disabled: true,
        route: '/contabilidad/marketing',
      },
      {
        keyName: 'security',
        title: 'Seguridad',
        description:
          'Incidentes, amenazas, vulnerabilidades, cumplimiento etc.',
        icon: faShieldHalved,
        disabled: true,
        route: '/contabilidad/marketing',
      },
      {
        keyName: 'billing',
        title: 'Cobranza',
        description:
          'Metricas de facturación, cartera, morosidad, cobros y más',
        icon: faHandHoldingDollar,
        disabled: true,
        route: '/contabilidad/ventas-generales',
      },
      {
        keyName: 'payments',
        title: 'Pagos',
        icon: faFileInvoiceDollar,
        disabled: true,
        description: 'Métricas de pagos, transacciones, tasas, disputas etc.',
        route: '/dashboard/tesoreria',
      },
      {
        keyName: 'costs',
        title: 'Costos',
        icon: faFileInvoiceDollar,
        disabled: true,
        description:
          'Gastos de materiales, logística, producción, RRHH, infraestructura etc.',
        route: '/dashboard/tesoreria',
      },
      {
        keyName: 'treasury',
        title: 'Tesoreria',
        icon: faFileInvoiceDollar,
        disabled: false,
        description: 'Informes de ventas, pagos, anulaciones y recaudos.',
        route: '/dashboard/tesoreria',
      },
    ],
  },
  {
    keyName: 'datastudio',
    icon: faFileAlt,
    disabled: true,
    title: 'DataStudio',
    route: '/datastudio',
  },
  {
    keyName: 'aicommsuite',
    icon: faUsers,
    disabled: true,
    title: 'AI CommSuite Tools',
    route: '/aicommsuite',
  },
  {
    keyName: 'accounting',
    icon: faHome,
    title: 'contabilidad',
    route: '/contabilidad',
    subModules: [
      {
        keyName: 'upload-file',
        title: 'Subir archivo',
        description: 'Sube tus archivos contables aquí',
        icon: faUpload,
        disabled: false,
        route: '/dashboard/subir-archivo',
      },
      {
        keyName: 'search-sales-files',
        title: 'Reportes',
        icon: faSearch,
        disabled: false,
        description: 'Encuentra tus franquisias, pagos, ventas y mas.',
        route: '/contabilidad/buscar-archivos-ventas',
      },
      // {
      //   keyName: 'keyt-test',
      //   title: 'test',
      //   description: 'Sube tus archivos contables aquí',
      //   icon: faUpload,
      //   disabled: false,
      //   route: '/dashboard/subir-archivo',
      //   subModules: [
      //     {
      //       keyName: 'key-test1',
      //       title: 'prueba',
      //       description: 'esto es test 1',
      //       icon: faUpload,
      //       disabled: false,
      //       route: '/dashboard/subir-archivo',
      //     },
      //     {
      //       keyName: 'key-test2',
      //       title: 'tasdasd',
      //       description: 'esto es test 2',
      //       icon: faUpload,
      //       disabled: false,
      //       route: '/dashboard/subir-archivo',
      //       subModules: [
      //         {
      //           keyName: 'carlos1',
      //           title: 'carlos1',
      //           description: 'esto es test 1',
      //           icon: faUpload,
      //           disabled: false,
      //           route: '/dashboard/subir-archivo',
      //         },
      //         {
      //           keyName: 'carlos2',
      //           title: 'carlos2',
      //           description: 'esto es test 2',
      //           icon: faUpload,
      //           disabled: false,
      //           route: '/dashboard/subir-archivo',
      //         },
      //       ],
      //     },
      //     {
      //       keyName: 'key-test3',
      //       title: 'zxczxc',
      //       description: 'esto es test 3',
      //       icon: faUpload,
      //       disabled: false,
      //       route: '/dashboard/subir-archivo',
      //     },
      //   ],
      // },
    ],
  },
]
