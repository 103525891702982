import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert, { AlertColor } from '@mui/material/Alert'

interface CustomAlertProps {
  open: boolean
  onClose: () => void
  severity: AlertColor
  message: string
}

/**
 * CustomAlert component displays a customized alert message using Material-UI Snackbar and Alert components.
 *
 * @component
 * @example
 * ```tsx
 * <CustomAlert
 *   open={true}
 *   onClose={() => handleAlertClose()}
 *   severity="error"
 *   message="An error occurred"
 * />
 * ```
 *
 * @param {object} props - The component props.
 * @param {boolean} props.open - Determines whether the alert is open or not.
 * @param {function} props.onClose - Callback function to be executed when the alert is closed.
 * @param {string} props.severity - The severity of the alert. Can be one of "error", "warning", "info", or "success".
 * @param {string} props.message - The message to be displayed in the alert.
 * @returns {JSX.Element} The rendered CustomAlert component.
 */
const CustomAlert: React.FC<CustomAlertProps> = ({
  open,
  onClose,
  severity,
  message,
}) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
      <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default CustomAlert
