// Funciones de utilidad
export function validateCedula(cedula: string): boolean {
  // Lógica para validar la cédula (debe tener más de 5 números y menos de 15)
  const regex = /^[0-9]{6,14}$/
  return regex.test(cedula)
}

export function validatePassword(password: string): boolean {
  // Lógica para validar la contraseña (debe tener más de 5 caracteres)
  return password.length > 5
}

export const formatToCOP = (amount: number): string => {
  // Separador de miles y decimales en el formato colombiano
  try {
    // Convertir el número a una cadena con formato colombiano
    const formattedNumber: string = amount.toLocaleString('es-CO', {
      style: 'currency',
      currency: 'COP',
    })
    return formattedNumber
  } catch (error) {
    return '-'
  }
}


//METODO STANDART PARA HACER PETICIONES FETCH
export async function fetchWithToken(url: string, options: RequestInit = {}): Promise<Response> {
  const token = localStorage.getItem('token')?.replace(/^"(.*)"$/, '$1'); // Obtener el token del almacenamiento local
  const idToken = localStorage.getItem('IdToken')?.replace(/^"(.*)"$/, '$1')
  // Configurar headers para incluir el token de autorización si está presente
  const headers = new Headers(options.headers || {});
  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('idToken', `${idToken}`);
  }

  const response = await fetch(url, { ...options, headers });

  // Verificar si el token ha expirado
  if (response.status === 401) {
    const refreshed = await refreshToken();
    if (refreshed) {
      return fetchWithToken(url, options); // Reintentar la solicitud con el nuevo token
    } else {
      throw new Error('No se pudo refrescar el token');
    }
  }

  return response;
}

//FUNCION PARA RESFRESCAR EL TOKEN
async function refreshToken(): Promise<boolean> {
  try {
    const response = await fetch('/api/token/refresh', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ refreshToken: localStorage.getItem('refreshToken') }) // Suponemos que el refresh token está almacenado aquí
    });

    if (!response.ok) {
      throw new Error('Error refrescando el token');
    }

    const data = await response.json();
    localStorage.setItem('token', data.accessToken); // Actualizar el token de acceso en localStorage
    return true;
  } catch (error) {
    console.error('Error refrescando el token:', error);
    return false;
  }
}

//Headers API
export function getHeaders(): Record<string, string> {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }

  const idToken = localStorage.getItem('IdToken')?.replace(/^"(.*)"$/, '$1')
  if (idToken) {
    headers.Authorization = `Bearer ${idToken}`
  }

  const accessToken = localStorage
    .getItem('accessToken')
    ?.replace(/^"(.*)"$/, '$1')
  if (accessToken) {
    headers['Access-Token'] = accessToken
  }

  return headers
}
