import { getHeaders } from 'src/utils/utils'
import { IPermission, ITablePermission } from './AdminPermissions.interface'

const BASE_URL = process.env.REACT_APP_API_IAM || 'NO_URL'
export const createPermission = async ({
  appId,
  moduleId,
  resourceId,
  name,
  params,
}: IPermission) => {
  const endpoint = `${BASE_URL}/permission/create`
  console.group(`Creating Permission: ${name}`)
  console.log('Request Details:', { appId, moduleId, resourceId, name, params })
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        appId,
        moduleId,
        resourceId,
        name,
        params,
      }),
    })
    const data = await response.json()
    console.log('Response:', data)
    if (!response.ok) {
      throw new Error(data.error?.message || 'A ocurrido un error inesperado')
    }
    console.groupEnd()
    return data
  } catch (error) {
    console.error('Request Failed:', error)
    console.groupEnd()
    throw error
  }
}

/**
 * Retrieves the permissions from the server.
 * @returns {Promise<any>} A promise that resolves to the permissions data.
 * @throws {Error} If an error occurs during the retrieval process.
 */
export const getPermissions = async () => {
  try {
    const response = await fetch(`${BASE_URL}/permission/get-permissions`, {
      method: 'GET',
      headers: getHeaders(),
    })
    const data = await response.json()
    if (!response.ok && response) {
      throw new Error(data.error.message || 'A ocurrido un error inesperado')
    }
    return data.data
  } catch (error) {
    console.error('Error en la creación de módulo', error)
    throw error
  }
}

/**
 * Retrieves a permission from the server based on the provided parameters.
 *
 * @param permission - The name of the permission to retrieve.
 * @param moduleId - The ID of the module associated with the permission.
 * @param appId - The ID of the application associated with the permission.
 * @param resourceId - The ID of the resource associated with the permission.
 * @returns A Promise that resolves to the retrieved permission data.
 * @throws If an error occurs during the retrieval process.
 */
export const getPermission = async ({
  permission,
  moduleId,
  appId,
  resourceId,
}: ITablePermission) => {
  try {
    const response = await fetch(
      `${BASE_URL}/permission/get-permission?name=${permission}&moduleId=${moduleId}&appId=${appId}&resourceId=${resourceId}`,
      {
        method: 'GET',
        headers: getHeaders(),
      },
    )
    const data = await response.json()
    if (!response.ok && response) {
      throw new Error(data.error.message || 'A ocurrido un error inesperado')
    }
    return data.data
  } catch (error) {
    console.error('Error en la creación de módulo', error)
    throw error
  }
}

/**
 * Deletes a permission from the table.
 * @param permission - The name of the permission to delete.
 * @param moduleId - The ID of the module.
 * @param appId - The ID of the app.
 * @param resourceId - The ID of the resource.
 * @returns A Promise that resolves to the JSON response from the server.
 * @throws If the network response is not ok or an error occurs during the deletion.
 */
export const deletePermission = async ({
  permission,
  moduleId,
  appId,
  resourceId,
}: ITablePermission) => {
  try {
    const response = await fetch(`${BASE_URL}/permission/delete`, {
      method: 'DELETE',
      headers: getHeaders(),
      body: JSON.stringify({ name: permission, moduleId, appId, resourceId }),
    })

    if (!response.ok && response) {
      throw new Error('Network response was not ok')
    }

    return await response.json()
  } catch (error) {
    console.error('Error en la eliminación de módulo', error)
    throw error
  }
}

/**
 * Updates the permissions for a given user.
 * @param data - The permission data to be updated.
 * @returns A Promise that resolves to the updated permission data.
 * @throws If the network response is not ok or an error occurs during the update.
 */
export const updatePermissions = async (data: IPermission) => {
  try {
    const response = await fetch(`${BASE_URL}/permission/update`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(data),
    })

    if (!response.ok && response) {
      throw new Error('Network response was not ok')
    }

    return await response.json()
  } catch (error) {
    console.error('Error en la actualización de módulo', error)
    throw error
  }
}
