import { fetchWithToken, getHeaders } from 'src/utils/utils'
import { IGroup } from './AdminGroup.interface'

const BASE_URL = process.env.REACT_APP_API_IAM || 'NO_URL'
/**
 * Creates a new group with the specified name and permissions.
 * @param {IGroup} group - The group object containing the name and permissions.
 * @returns {Promise<any>} - A promise that resolves to the response data from the server.
 * @throws {Error} - If an error occurs during the creation of the group.
 */
export const createGroup = async ({ name, permissions }: IGroup) => {
  try {
    const response = await fetchWithToken(`${BASE_URL}/group/create`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ name, permissions }),
    })
    const data = await response.json()
    if (!response.ok && response) {
      throw new Error(data.error.message || 'A ocurrido un error inesperado')
    }
    return data
  } catch (error) {
    console.error('Error en la creación de módulo', error)
    throw error
  }
}

/**
 * Retrieves the groups from the server.
 * @param options - Optional parameters for the request.
 * @param options.type - Specifies the type of groups to retrieve. Defaults to true.
 * @returns A Promise that resolves to the array of groups.
 * @throws If an error occurs during the request or if the response is not successful.
 */
export const getGroups = async ({ type = true }: { type?: boolean } = {}) => {
  try {
    const response = await fetchWithToken(`${BASE_URL}/group/get-groups`, {
      method: 'GET',
      headers: getHeaders(),
    })
    const data = await response.json()
    if (!response.ok) {
      throw new Error(data.error.message)
    }

    return data.data
  } catch (error) {
    console.error('Error en la creación de módulo', error)
    throw error
  }
}

/**
 * Deletes a group by its ID.
 * @param id - The ID of the group to delete.
 * @returns A Promise that resolves to the deleted group data.
 * @throws If an error occurs during the deletion process.
 */
export const deleteGroup = async (id: number) => {
  try {
    const response = await fetchWithToken(`${BASE_URL}/group/delete/${id}`, {
      method: 'DELETE',
      headers: getHeaders(),
    })

    const data = await response.json()
    if (!response.ok) {
      throw new Error(data.error.message || 'A ocurrido un error inesperado')
    }

    return data
  } catch (error) {
    console.error('Error en la eliminación de módulo', error)
    throw error
  }
}

/**
 * Updates a group with the specified name and permissions.
 * @param {IGroup} group - The group object containing the name and permissions to update.
 * @returns {Promise<any>} - A promise that resolves to the updated group data.
 * @throws {Error} - If an error occurs during the update process.
 */
export const updateGroup = async ({ name, permissions }: IGroup) => {
  try {
    const response = await fetchWithToken(`${BASE_URL}/group/update`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify({
        name,
        permissions,
      }),
    })
    const data = await response.json()
    if (!response.ok) {
      throw new Error(data.error.message || 'A ocurrido un error inesperado')
    }

    return data
  } catch (error) {
    console.error('Error en la actualización de módulo', error)
    throw error
  }
}
