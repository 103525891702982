import { jwtDecode } from 'jwt-decode'
import React, { createContext, useState, useEffect, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  IPermissions,
  ILocalRoutes,
} from 'src/components/Sidebar/Sidebar.Interface'
import { permissions as dummyPermissions } from 'src/contexts/dummyRoutes'

/**
 * @typedef {Object} AuthContextType
 * @property {boolean} isAuthenticated - Indicates if the user is authenticated.
 * @property {boolean} isLoading - Indicates if the authentication status is being loaded.
 * @property {any} user - The authenticated user.
 * @property {IPermissions[]} permissions - The permissions of the authenticated user.
 * @property {Function} setIsAuthenticated - Function to set the authenticated status.
 * @property {Function} setUser - Function to set the authenticated user.
 * @property {Function} authenticateUser - Function to authenticate the user.
 * @property {Function} logoutUser - Function to log out the user.
 * @property {Function} getModules - Function to get the modules.
 * @property {Function} getSubModules - Function to get the submodules.
 */
interface AuthContextType {
  isAuthenticated: boolean
  isLoading: boolean
  user: any
  permissions: IPermissions[]
  setIsAuthenticated: (authenticated: boolean) => void
  setUser: (user: any) => void
  authenticateUser: (token: string) => void
  logoutUser: () => void
  getModules: (items: ILocalRoutes[], items2: IPermissions[]) => ILocalRoutes[]
  getSubModules: (
    items: ILocalRoutes[],
    path: string,
  ) => ILocalRoutes[] | undefined
}

/**
 * @constant
 * @type {React.Context<AuthContextType>}
 */
export const AuthContext = createContext<AuthContextType>({} as AuthContextType)

/**
 * AuthProvider component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The children nodes.
 * @returns {JSX.Element} The rendered AuthProvider component.
 */
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [user, setUser] = useState<any>(null)
  const [permissions, setPermissions] =
    useState<IPermissions[]>(dummyPermissions)
  const [isLoading, setIsLoading] = useState(true)

  /**
   * Logs out the user.
   */
  const logoutUser = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('user')
    localStorage.removeItem('IdToken')
    setIsAuthenticated(false)
    setUser(null)
  }

  /**
   * Authenticates the user.
   *
   * @param {string} token - The authentication token.
   */
  const authenticateUser = (token: string) => {
    localStorage.setItem('accessToken', token)
    setIsAuthenticated(true)
  }

  /**
   * Gets the modules.
   *
   * @param {ILocalRoutes[]} items - The items.
   * @param {IPermissions[]} items2 - The modules.
   * @returns {ILocalRoutes[]} The modules.
   */
  const getModules = (
    items: ILocalRoutes[],
    items2: IPermissions[],
  ): ILocalRoutes[] => {
    const newItems: ILocalRoutes[] = []
    items.forEach((item) => {
      const found = items2.find((item2) => item.keyName === item2.keyName)
      if (found) {
        newItems.push(item)
      }
    })
    return newItems
  }

  /**
   * Gets the submodules.
   *
   * @param {ILocalRoutes[]} items - The items.
   * @param {string} path - The path.
   * @returns {ILocalRoutes['subModules']} The submodules.
   */
  const getSubModules = (
    items: ILocalRoutes[],
    path: string,
  ): ILocalRoutes['subModules'] => {
    const item = items.find(
      (item) => item.title.toLocaleLowerCase() === path.toLocaleLowerCase(),
    )
    return item?.subModules
  }

  /**
   * Verifies the access token when the application loads.
   */
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      setIsAuthenticated(true)
      setPermissions(dummyPermissions)
      const user = jwtDecode(accessToken)
      localStorage.setItem('user', JSON.stringify(user))
      setUser(
        user ?? {
          username: 'Usuario',
          email: '',
        },
      )
    }
    setIsLoading(false)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        user,
        permissions,
        setIsAuthenticated,
        setUser,
        authenticateUser,
        logoutUser,
        getModules,
        getSubModules,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
