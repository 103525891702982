/**
 * @file Layout.tsx
 * File in charge of rendering the layout of the dashboard.
 * It includes the Header and Sidebar components, as well as the main content.
 * The Sidebar is used for navigation between the different modules of the dashboard.

 */

import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom' // Used for navigation between routes
import Header from 'src/components/Header/Header'
import Sidebar from 'src/components/Sidebar/Sidebar'
import styles from './Layout.module.scss'
import { AuthContext } from 'src/contexts/AuthContext'
import { routes } from 'src/components/DashboardMenu/Routes'

// Definition of the properties that the Layout component will receive
interface LayoutProps {
  children: React.ReactNode // Child components to be rendered in the main area
}

// Main function of the Layout component
/**
 * Represents the layout component for the dashboard.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to render.
 * @returns {React.ReactNode} The rendered layout component.
 */
export default function Layout({ children }: LayoutProps) {
  const { permissions, getModules } = useContext(AuthContext)

  const navigate = useNavigate()
  const location = useLocation() // Get the current location
  const [activeItem, setActiveItem] = useState('dashboard') // State to handle the active item in the Sidebar

  /**
   * Updates the active item based on the current route.
   */
  useEffect(() => {
    // Get the part of the route that represents the current module
    const currentModule = location.pathname.split('/')[1]
    setActiveItem(currentModule)
  }, [location])

  /**
   * Function that is executed when an item in the Sidebar is selected.
   * @param {string} key - The key of the selected item.
   */
  const onItemSelect = (key: string) => {
    setActiveItem(key) // Update the active item in the state
    navigate(`/dashboard/${key}`) // Redirect to the new route based on the selected item
  }

  // Configuration of the items in the Sidebar, including the icon image, title, and active state

  // JSX structure of the Layout component
  return (
    <div className={styles.layout}>
      <Header /> {/* Render the Header with the page title */}
      <div className={styles.content}>
        {/* Render the Sidebar with the items and the selection function */}
        <Sidebar
          items={getModules(routes, permissions)}
          onItemSelect={onItemSelect}
        />
        {/* <pre>
          {JSON.stringify(getNewItems(sidebarItems, sidebarItems2), null, 2)}
        </pre> */}
        <main className={styles.main}>
          {children} {/* Render the main content */}
        </main>
      </div>
    </div>
  )
}
