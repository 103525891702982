import React from 'react'
import './AdminUser.scss'
const AdminUser = () => {
  const steps = [
    {
      id: '1',
      title: 'Crear módulo',
      description: 'Datos personales',
    },
    {
      id: '2',
      title: 'Editar módulo',
      description: 'Permisos',
    },
    {
      id: '3',
      title: 'Eliminar módulo',
      description: 'Confirmar',
    },
  ]
  const formFields = [
    {
      id: 'name',
      label: 'Nombre',
      placeholder: 'Ingresa tu nombre',
      value: 'hola',
      onChange: () => console.log('hola'),
    },
    { id: 'lastName', label: 'Apellido', placeholder: 'Ingresa tu apellido' },
    {
      id: 'email',
      label: 'Correo electrónico',
      placeholder: 'Ingresa tu correo electrónico',
    },
    {
      id: 'phone',
      label: 'Teléfono',
      placeholder: 'Ingresa tu número de teléfono',
    },
    {
      id: 'password',
      label: 'Contraseña',
      placeholder: 'Ingresa tu contraseña',
      type: 'password',
    },
    {
      id: 'confirmPassword',
      label: 'Confirmar contraseña',
      placeholder: 'Confirma tu contraseña',
      type: 'password',
    },
  ]
  return (
    <div className="container-admin-user">
      <div className="container-steps">
        {steps.map((step, index) => (
          <div key={step.id} className={`step ${index === 0 ? 'active' : ''}`}>
            <div className="step--circle">{index + 1}</div>
            <p>{step.description}</p>
          </div>
        ))}
      </div>
      <form className="form">
        {formFields.map((field) => (
          <div className="form-group" key={field.id}>
            <label htmlFor={field.id} className="form-group-label">
              {field.label}
            </label>
            <input
              type={field.type || 'text'}
              id={field.id}
              className="form-group-input"
              placeholder={field.placeholder}
              value={field.value || ''}
              disabled
            />
          </div>
        ))}
      </form>
    </div>
  )
}

export default AdminUser
