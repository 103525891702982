import React, { Fragment, useState } from 'react'
import { IModule } from 'src/components/Sidebar/Sidebar.Interface'
import SubModuleSelector from './SubModuleSelector'
import { Autocomplete, TextField } from '@mui/material'

const TopLevelModuleSelector = ({
  modules,
  selectedModule,
  onModuleChange,
}: {
  modules: IModule[]
  selectedModule: IModule
  onModuleChange: (module: IModule) => void
}) => {
  // Asegúrate de que se selecciona el módulo correcto en el `Autocomplete`
  const selectedModuleFinded =
    modules.find((m) => m.path === selectedModule?.path ?? '') || null

  // Manejo del cambio de selección
  const handleChange = (event: any, newValue: IModule | null) => {
    if (newValue !== null) {
      onModuleChange(newValue)
    }
  }

  return (
    <Fragment>
      <div className="form-group">
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={modules}
          sx={{ width: 200 }}
          className="form-group-input"
          size="small"
          value={selectedModuleFinded}
          onChange={handleChange}
          getOptionLabel={(option: IModule) => option.keyName}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Modulos principales"
              variant="outlined"
            />
          )}
        />
      </div>
      {/* {selectedModuleFinded && (
        <SubModuleSelector
          subModule={selectedModuleFinded}
          onSubModuleNameChange={onModuleChange as (module: IModule) => void}
        />
      )} */}
    </Fragment>
  )
}

export default TopLevelModuleSelector
