import Step1 from 'src/components/Profile/Step1/Step1'
import Step2 from 'src/components/Profile/Step2/Step2'
import styles from './Profile.module.scss'
import Tabs from 'src/components/MUI/Tabs/tabs'
import React from 'react'
const Profile = () => {
  const tabs = [
    {
      label: 'Información de usuario',
      content: <Step1 />,
      disabled: false,
    },
    {
      label: 'Usuarios adminsitradores',
      content: <Step2 />,
      disabled: false,
    },
  ]
  return (
    <div className={styles.profile}>
      <div className={styles.profileContent}>
        <div className={styles.profileContentFilters}>
          <h1>Buscar usuario</h1>
          <div className={styles.profileContentFields}>
            <div className={styles.profileContentInput}>
              <label htmlFor="searchUser">Cédula</label>
              <input
                type="number"
                id="searchUser"
                placeholder="Cédula de usuario"
              />
            </div>
            <button className={styles.buttonSearch}>Buscar</button>
          </div>
        </div>
        <div className={styles.profileContentTabs}>
          <Tabs tabs={tabs} />
        </div>
      </div>
    </div>
  )
}

export default Profile
