import styles from './Step1.module.scss'
import DummySelfie from 'src/assets/images/Dashboard/SearchUser/dummySelfie.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownload, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
/**
 * Component for Step 1 of the profile setup.
 *
 * @component
 * @example
 * ```tsx
 * <Step1 />
 * ```
 */

const Step1 = () => {
  const dummyFiles = [
    {
      name: 'Contrato1',
      weight: '14.4kb',
      type: 'document',
    },
    {
      name: 'Contrato2',
      weight: '14.4kb',
      type: 'document',
    },
    {
      name: 'Contrato3',
      weight: '14.4kb',
      type: 'document',
    },
  ]
  const dummyImages = [
    {
      name: 'Selfie',
      weight: '14.4kb',
      type: 'image',
    },
    {
      name: 'Cédula frontal',
      weight: '14.4kb',
      type: 'image',
    },
    {
      name: 'Cédula posterior',
      weight: '14.4kb',
      type: 'image',
    },
  ]
  const formFields = [
    {
      id: 'name',
      label: 'Nombre',
      placeholder: 'Ingresa tu nombre',
      value: 'hola',
      onChange: () => console.log('hola'),
    },
    { id: 'lastName', label: 'Apellido', placeholder: 'Ingresa tu apellido' },
    {
      id: 'email',
      label: 'Correo electrónico',
      placeholder: 'Ingresa tu correo electrónico',
    },
    {
      id: 'phone',
      label: 'Teléfono',
      placeholder: 'Ingresa tu número de teléfono',
    },
    { id: 'address', label: 'Dirección', placeholder: 'Ingresa tu dirección' },
    { id: 'city', label: 'Ciudad', placeholder: 'Ingresa tu ciudad' },
    { id: 'country', label: 'País', placeholder: 'Ingresa tu país' },
    {
      id: 'postalCode',
      label: 'Código postal',
      placeholder: 'Ingresa tu código postal',
    },
    {
      id: 'password',
      label: 'Contraseña',
      placeholder: 'Ingresa tu contraseña',
      type: 'password',
    },
    {
      id: 'confirmPassword',
      label: 'Confirmar contraseña',
      placeholder: 'Confirma tu contraseña',
      type: 'password',
    },
  ]

  return (
    <div className={styles.step1}>
      <div className={styles.form}>
        {formFields.map((field) => (
          <div className={styles.formGroup} key={field.id}>
            <label htmlFor={field.id} className={styles.formLabel}>
              {field.label}
            </label>
            <input
              type={field.type || 'text'}
              id={field.id}
              className={styles.formInput}
              placeholder={field.placeholder}
              value={field.value || ''}
              disabled
            />
          </div>
        ))}
      </div>
      <div className={styles.containerImages}>
        <div className={styles.images}>
          <h2>Fotos</h2>
          {dummyImages &&
            dummyImages.map((file, index) => (
              <div className={styles.image} key={index}>
                <div className={styles.group}>
                  <div className={styles.containerImage}>
                    <img src={DummySelfie} alt="profile" />
                    <button className={styles.button}>Ver</button>
                  </div>
                  <div>
                    <p>
                      <strong>Nombre:</strong> {file.name}
                    </p>
                    <p>
                      <strong>Peso:</strong> {file.weight}
                    </p>
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faCloudDownload}
                  color="#000"
                  className={styles.iconDownload}
                  onClick={() => console.log('download')}
                />
              </div>
            ))}
        </div>
        <div className={styles.images}>
          <h2>Documentos</h2>
          {dummyFiles &&
            dummyFiles.map((file, index) => (
              <div className={styles.image} key={index}>
                <div className={styles.group}>
                  {file.type === 'image' ? (
                    <div className={styles.containerImage}>
                      <img src={DummySelfie} alt="profile" />
                      <button className={styles.button}>Ver</button>
                    </div>
                  ) : (
                    <div>
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        color="#000"
                        className={styles.iconPdf}
                      />
                    </div>
                  )}
                  <div>
                    <p>
                      <strong>Nombre:</strong> {file.name}
                    </p>
                    <p>
                      <strong>Peso:</strong> {file.weight}
                    </p>
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faCloudDownload}
                  color="#000"
                  className={styles.iconDownload}
                  onClick={() => console.log('download')}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
export default Step1
